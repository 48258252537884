import axios from 'axios';

export const api = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
});

export default function authHeader() {
    const user = JSON.parse(localStorage.getItem("currentUser"));

    if (user && user.token) {
        return {
            "Authorization": "Token " + user.token.token,
        };
    } else {
        return {};
    }
}

export const LOGIN_API = async (playload) => {
    const response = await api.post('zaho/v1/login/', playload);
    return response;
}
export const REGISTER_API = async (playload) => {
    const response = await api.post('zaho/v1/register/', playload);
    return response;
}
export const ACTIVATE_API = async (playload, header) => {
    const response = await api.post('zaho/v1/activate/', playload, { headers: header });
    return response;
}
export const RESEND_MAIL_API = async (playload) => {
    const response = await api.post('zaho/v1/mail/', playload);
    return response;
}
export const GET_TRANO_API = async () => {
    const response = await api.get('vakansy/v1/trano/');
    return response;
}

export const GET_TRANO_API_REVIEW = async (trano_id) => {
    try {
        const response = await api.get(`vakansy/v1/reviews`, {
            params: {
                trano_id: trano_id
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching reviews:', error);
        throw error;
    }
};


export const POST_TRANO_API_REVIEW = async (reviewData) => {
    try {
        const response = await api.post(`vakansy/v1/reviews/`, reviewData, { headers: authHeader() });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            alert('You need to login to submit a review');
        } else {
            console.error('Error posting review:', error);
            alert('Error posting review');
        }
        throw error;
    }
};

export const GET_TRANO_API_WITH_CATEGORIES = async (playload) => {
    const response = await api.get('vakansy/v1/trano_cat/' + playload);
    return response;
}

export const GET_TRANO_API_WITH_DESTINATION = async (playload) => {
    const response = await api.get('vakansy/v1/trano_dest/' + playload);
    return response;
}

export const GET_DESTINATION = async () => {
    const response = await api.get('vakansy/v1/destination/');
    return response;
}

export const GET_CATEGORY = async () => {
    const response = await api.get('vakansy/v1/category/');
    return response;
}
export const GET_BOOKED_TRANO_API = async () => {
    const response = await api.get('vakansy/v1/booked/');
    return response;
}
export const GET_BOOKED_TRANO_API_WITH_DESTINATION = async (playload) => {
    const response = await api.get('vakansy/v1/booked_dest/' + playload);
    return response;
}
export const GET_BOOKED_TRANO_API_WITH_CATEGORIES = async (playload) => {
    const response = await api.get('vakansy/v1/booked_cat/' + playload);
    return response;
}
export const GET_PROFILE_API = async () => {
    const response = await api.get('zaho/v1/profile/', { headers: authHeader() })
    return response;
}
export const GET_BOOK = async (playload) => {
    const response = await api.get('vakansy/v1/trano/book/', playload);
    return response;
}
export const BOOK = async (playload) => {
    const response = await api.post('vakansy/v1/create/', playload, { headers: authHeader() });
    return response;
}
export const GET_MY_TRANSACTIONS = async () => {
    const response = await api.get('zaho/v1/transactions/', { headers: authHeader() });
    return response;
}
export const GET_MY_BOOKING = async () => {
    const response = await api.get('vakansy/v1/my/booking/', { headers: authHeader() });
    return response;
}

export const ADMIN_WITHDRAW_API = async (playload) => {
    const response = await api.post('zaho/v1/admin/withdraw/', playload, { headers: authHeader() });
    return response;
}

export const ADMIN_DEPOSIT_API = async (playload) => {
    const response = await api.post('zaho/v1/admin/deposit/', playload, { headers: authHeader() });
    return response;
}

export const COMPLETE_BOOK = async (playload) => {
    const response = await api.post('vakansy/v1/complete/payment/', playload, { headers: authHeader() });
    return response;
}
export const DEPOSIT = async (playload) => {
    const response = await api.post('zaho/v1/mvola/deposit/', playload, { headers: authHeader() });
    return response;
}
export const COMPTE = async () => {
    const response = await api.get('zaho/v1/mvola/compte/', { headers: authHeader() });
    return response;
}

export const WITHDRAW = async (playload) => {
    const response = await api.post('vakansy/v1/trano/book/', playload, { headers: authHeader() });
    return response;
}



export const GET_VISITOR = async (playload) => {
    const response = await api.get('zaho/v1/visitor/', playload, { headers: authHeader() });
    return response;
}

export const REGISTER_TRANO = async (payload) => {
    const formData = new FormData();
    formData.append('images', payload.images);
    formData.append('image_indoor', payload.image_indoor);
    formData.append('image_outdoor', payload.image_outdoor);
    formData.append('description', payload.description);
    formData.append('name', payload.name);
    formData.append('category', payload.category);
    formData.append('destination', payload.destination);
    formData.append('price', payload.price);
    formData.append('is_secured', payload.is_secured);
    formData.append('tv', payload.tv);
    formData.append('wifi', payload.wifi);
    formData.append('can_cook', payload.can_cook);
    formData.append('max_pers', payload.max_pers);
    formData.append('nbr_beds', payload.nbr_beds);
    formData.append('air_con', payload.air_con);
    formData.append('parking', payload.parking);
    formData.append('eau_chaude', payload.eau_chaude);
    formData.append('spa', payload.spa);
    formData.append('free_breakfast', payload.free_breakfast);
    formData.append('restaurant', payload.restaurant);
    formData.append('piscine', payload.piscine);
    formData.append('commission_rate', payload.commission_rate);
    formData.append('extra', payload.extra);
    formData.append('longitude', payload.longitude);
    formData.append('latitude', payload.latitude);
    formData.append('addresse', payload.addresse);
    formData.append('contact', payload.contact);

    const response = await api.post('vakansy/v1/new/trano/', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            ...authHeader(),
        },
    });

    return response;
}

export const GET_MY_TRANO = async () => {
    const response = await api.get('vakansy/v1/my/trano/', { headers: authHeader() });
    return response;
}

export const CREATE_CHECKOUT = async () => {
    const response = await api.post('zaho/v1/create-checkout-session/', {}, { headers: authHeader() });
    return response.data;
}

export const STRIPE_DEPOSIT = async (playload) => {
    const response = await api.post('zaho/v1/create-payment-intent', playload, { headers: authHeader() });
    return response.data;
}

export const STRIPE_DEPOSIT_SUCCEED = async (playload) => {
    const response = await api.post('zaho/v1/stripe/notification/', playload, { headers: authHeader() });
    return response;
}