import React, { Suspense } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/headers/light.js";
import SliderCard from "./components/SliderCard.js";

export default () => {
    return (
        <AnimationRevealPage>
            <Header />
            <Suspense fallback={<p>loading...</p>}>
                <SliderCard />
            </Suspense>
            <Footer />
        </AnimationRevealPage>
    );
}
