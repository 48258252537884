import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import { ProductContext, ProductDispath } from "Context/ContextProvider";
import styled from "styled-components";
import 'react-toastify/dist/ReactToastify.css';
import { motion } from "framer-motion";

import { SectionHeading } from "components/misc/Headings.js";
import { toast } from 'react-toastify';
import { COMPTE, GET_MY_TRANSACTIONS } from "helpers/api";
import Modal from './stripe/index';
import OrangeModal from "./mobile/orange";
import TelmaModal from "./mobile/telma";

const Row = tw.div`flex flex-col lg:flex-row -mb-10 w-full lg:mr-12`;
const Heading = tw(SectionHeading)`text-left text-xs`;
const PopularPostsContainer = tw.div`lg:w-2/3`;
const PostsContainer = tw.div`mt-2 flex flex-col sm:flex-row sm:justify-between lg:justify-start`;
const Post = tw(motion.a)`block sm:max-w-sm cursor-pointer mb-16 last:mb-0 sm:mb-0 sm:odd:mr-8 lg:mr-8 xl:mr-16`;
const Image = styled(motion.div)(props => [
    `background-image: url("${props.$imageSrc}");`,
    tw`h-16 w-1/2 mb-8 bg-cover bg-center rounded`
]);
const Title = tw.h5`mt-6 text-xs font-bold transition duration-300 group-hover:text-primary-500`;
const Description = tw.p`mt-2 font-medium text-secondary-100 leading-loose text-sm`;
const TitleTransaction = tw.span`mt-6 text-xs font-bold transition duration-300 group-hover:text-primary-500`;

const AuthorName = tw.h6`font-semibold text-lg`;

const Table = tw.table`min-w-full mb-8 divide-y divide-gray-200`;
const Td = tw.td`px-1 py-1 whitespace-nowrap`;


const RecentPostsContainer = styled.div`
  ${tw`mt-24 lg:mt-0`}
  ${PostsContainer} {
    ${tw`flex flex-wrap lg:flex-col`}
  }
  ${Post} {
    ${tw`flex justify-between mb-4 max-w-none w-full lg:w-auto sm:odd:pr-12 lg:odd:pr-0 mr-0`}
  }
  ${Title} {
    ${tw`text-base xl:text-lg mt-0 mr-4 lg:max-w-xs`}
  }
  ${AuthorName} {
    ${tw`mt-3 text-sm text-secondary-100 font-normal leading-none`}
  }
  ${Image} {
    ${tw`h-20 w-20 flex-shrink-0`}
  }
`;
const PostTextContainer = tw.div``
const InputContainer = tw.div`relative py-5 mt-4`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const ImagePaiement = tw.div`flex flex-wrap `;
const SubmitButton = tw.button`w-full sm:w-32 mb-8 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

export default () => {

    const { state } = useContext(ProductContext);
    const { dispath } = useContext(ProductDispath);
    const [data, setData] = useState()
    const [loaded, setLoaded] = useState()
    const [amount, setAmount] = useState(20000)


    const loadData = async () => {
        // pass = pass.value
        try {
            let res = await GET_MY_TRANSACTIONS()
            if (res.status === 200) {
                setData(res.data)
                // console.log(res.data)
                setLoaded(true)
            }

        } catch (error) {
            if (error.response.status === 401) {
                // alert("Session expiree")
                dispath({ type: "OUT_SESION" });
            }
        }
    };
    const handleSubmit = async () => {

        try {
            let res = await COMPTE()
            if (res.status === 200) {
                let data = res.data
                dispath({ type: "COMPTE", payload: data });
                let currentData = JSON.parse(localStorage.getItem('currentUser'))
                currentData['profile'] = data['profile']
                localStorage.setItem('currentUser', JSON.stringify(currentData))
            }


        } catch (error) {
            if (error.response.status === 401) {
                toast.error("Session expiree!")
                dispath({ type: "OUT_SESION" });
            }
        }
    };

    const [isOpenOrange, setIsOpenOrange] = useState(false);
    const [isOpen, setIsOpen] = useState(false);


    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    const handleCloseOModal = () => {
        setIsOpenOrange(false);
    };
    const handleCloseTModal = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        !loaded &&
            loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //Recommended: Only 2 Items
    const post =
    {
        postImageSrc: "",
        authorImageSrc: "",
        title: "",
        description:
            "Pour pouvoir faire une réservation, créditer votre compte ici en utilisant les méthodes de paiement qui vous convient.",
        authorName: "",
        authorProfile: "",
        url: ""
    }
    return (
        <Row>
            <PopularPostsContainer>
                <Heading>Details compte</Heading>

                <InputContainer>
                    <Label htmlFor="name-input">Profile id</Label>
                    <Input id="name-input" type="text" name="name" disabled placeholder={state.user.profile_id} />
                </InputContainer>
                <InputContainer>
                    <Label htmlFor="email-input">Solde en Ar</Label>
                    <Input id="name-input" type="text" name="name" disabled placeholder={state.user.mobile_money} />
                </InputContainer>
                <InputContainer>
                    <Label htmlFor="name-input">Addresse</Label>
                    <Input id="name-input" type="text" name="name" disabled placeholder={state.user.address} />
                </InputContainer>
                <InputContainer>
                    <Label htmlFor="email-input">Numero</Label>
                    <Input id="name-input" type="text" name="name" disabled placeholder={state.user.phone_number} />
                </InputContainer>
                {
                    state.user.is_tompontrano &&
                    <SubmitButton onClick={handleSubmit}>Dashboard</SubmitButton>
                }



            </PopularPostsContainer>
            <PopularPostsContainer>
                <Heading>Mode de paiement</Heading>
                <PostsContainer>
                    <Post className="group" initial="rest" whileHover="hover" animate="rest">

                        <Description>{post.description}</Description>

                        <ImagePaiement>
                            <Image
                                onClick={() => setIsOpenOrange(true)}
                                $imageSrc={"/logo-om.png"} />
                            <Image
                                // onClick={() => setIsOpen(true)} 
                                $imageSrc={"/logo-mvola.png"} />

                            <Image
                                onClick={() => openInNewTab('/stripe/pay')}
                                $imageSrc={"/logo-stripe.png"} />
                        </ImagePaiement>
                        <OrangeModal isOpen={isOpenOrange} onClose={handleCloseOModal} title="My Modal">
                            <p>This is the content of the modal.</p>
                        </OrangeModal>
                        <TelmaModal isOpen={isOpen} onClose={handleCloseTModal} title="My Modal">
                            <p>This is the content of the modal.</p>
                        </TelmaModal>

                        <SubmitButton onClick={handleSubmit}>  Actualiser </SubmitButton>
                    </Post>
                </PostsContainer>


            </PopularPostsContainer>
            <PopularPostsContainer>
                <RecentPostsContainer>
                    <Heading>Transactions</Heading>

                    {loaded &&
                        <Table>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <Td>
                                            <TitleTransaction>{item.transaction_type}: {item.motifs}</TitleTransaction>

                                            <AuthorName>{item.transaction_date}</AuthorName>
                                        </Td><Td>
                                            <AuthorName>
                                                {
                                                    item.transaction_type === "DEBIT" ? "-" : "+"
                                                }
                                                {item.amount} Ar
                                            </AuthorName>

                                        </Td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    }
                </RecentPostsContainer>
            </PopularPostsContainer>
        </Row>
    );
};
