import React, { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import tw from "twin.macro";
import "react-datepicker/dist/react-datepicker.css";
import { ProductContext, ProductDispath } from "../../Context/ContextProvider";
import TooltipSlider from "./slider";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as NavigationIcon } from "feather-icons/dist/icons/navigation.svg";
import { ReactComponent as CalendarIcon } from "feather-icons/dist/icons/calendar.svg";
import { ReactComponent as PersonIcon } from "feather-icons/dist/icons/users.svg";


const Container = tw.div`relative bg-white bg-opacity-75 px-8 items-center shadow-xs rounded-xl  w-full`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-4  flex flex-wrap justify-between`;
const ColumnHeading = tw.h6`text-xs font-bold pb-2`;
const ColumnMaxPers = tw.div`md:w-1/4 pb-6 pr-4 w-full `;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full  -mb-1 mr-2`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const SearchBarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1.5rem;
  column-gap: 1rem;
  background-color: #fff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 1rem;
  width: 100%;
  padding: 2rem;
  font-size: 0.75rem;

  @media (min-width: 640px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
  }
`;


export default function SearchBar() {
  const { state } = useContext(ProductContext);
  const { dispath } = useContext(ProductDispath);
  const params = useParams();
  const maxDate = new Date()


  const setDate = async (date) => {
    let count = await parseInt((new Date(state.end_date).getTime() - date.getTime()) / (1000 * 3600 * 24)) + 1
    let payloads = {
      'date_': date.toISOString().slice(0, 10),
      'type_': "start",
      'count': count
    }
    dispath({
      type: "FILTER",
      payload: { key: 'date', value: payloads }
    });
  }
  const setDateEnd = async (date) => {
    let count = await parseInt((date.getTime() - new Date(state.start_date).getTime()) / (1000 * 3600 * 24)) + 1
    let payloads = {
      'date_': date.toISOString().slice(0, 10),
      'type_': "end",
      'count': count
    }
    dispath({
      type: "FILTER",
      payload: { key: 'date', value: payloads }
    });

  }
  const [maxPers, setMaxPers] = useState(state.max_pers);
  const setMaxPersHandler = (e) => {
    e.preventDefault();
    let value = e.target.value
    setMaxPers(value);
    if (value > 0) {
      dispath({
        type: "FILTER",
        payload: { key: 'max_pers', value: value }
      });
    }

  };



  const CustomInput = ({ value, onClick }) => (
    <div className='calendar' onClick={onClick}>
      <input type='text' readOnly
        className="form-item" value={value} />
      <i className="bi bi-calendar-week"></i>
    </div>
  );
  const Column = tw.div`md:w-1/4 pb-3 pr-4 w-full `;
  const ColumnBudget = tw.div`md:w-1/4 pb-6 pr-4 w-full `;



  return (
    <SearchBarContainer>

      {
        params.destination &&
        <div>
          <ColumnHeading>
            <IconContainer>
              <NavigationIcon />
            </IconContainer>
            Destination
          </ColumnHeading>
          <input type='text' disabled className="form-item" value={params.destination} />
        </div>
      }
      {
        params.category &&
        <div>
          <ColumnHeading>
            <IconContainer>
              <NavigationIcon />
            </IconContainer>
            Categorie
          </ColumnHeading>
          <input type='text' disabled className="form-item" value={params.category} />
        </div>
      }

      <div>
        <ColumnHeading>
          <IconContainer>
            <PersonIcon />
          </IconContainer>
          Personnes
        </ColumnHeading>
        <input
          type="number"
          className="form-item"
          value={maxPers}
          onChange={setMaxPersHandler}
          placeholder={2}
        />
      </div>

      <div>
        <ColumnHeading>
          <IconContainer>
            <CalendarIcon />
          </IconContainer>
          Arrivée
        </ColumnHeading>
        <DatePicker
          dateFormat='dd/MM/yyyy'
          selected={new Date(state.start_date)}
          onChange={(date) => setDate(date)}
          selectsStart
          startDate={new Date(state.start_date)}
          endDate={new Date(state.end_date)}
          minDate={new Date()}
          customInput={<CustomInput />}
        />

      </div>
      <div>
        <ColumnHeading>
          <IconContainer>
            <CalendarIcon />
          </IconContainer>
          Départ
        </ColumnHeading>
        <DatePicker
          dateFormat='dd/MM/yyyy'
          selected={new Date(state.end_date)}
          onChange={(date) => setDateEnd(date)}
          selectsEnd
          startDate={new Date(state.start_date)}
          endDate={new Date(state.end_date)}
          minDate={new Date(state.start_date)}
          maxDate={maxDate.setMonth(maxDate.getMonth() + 6)}
          customInput={<CustomInput />}
        />

      </div>
      {
        (params.destination || params.category) &&
        <div>
          <ColumnHeading>
            <IconContainer>
              <PriceIcon />
            </IconContainer>
            Budget (Ar)
          </ColumnHeading>
          <TooltipSlider />
        </div>
      }


    </SearchBarContainer>
  );
}