import Buttons from "./Buttons";
import { Link } from "react-router-dom";
import { UNITE } from "helpers/constant";
import tw from "twin.macro";
import styled from "styled-components";

export default function BasketItem(props) {

  const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;

  return (
    <div className="basket_item">
      <Link className="basket_link" to={`/trano/details/${props.id}`}>
        <div className="basket_img">
          <img src={props.images} alt="basket_item" />
        </div>
        <PlanFeatures>
          <span className="feature">{props.name}</span>
          <span>{(props.price).toLocaleString()} {UNITE} / jour</span>
        </PlanFeatures>

      </Link>
      <div className="basket_counter">
        <Buttons {...props} />
      </div>
    </div>
  );
}
