import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import './Map.css';

function Map(
  {
    long = -15.716667,
    lat = 46.316667,
    name = "Hotel Miami",
    price = "5000"
  }
) {

  return (
    <MapContainer center={[long, lat]} zoom={13} scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[long, lat]}>
        <Popup>
          {name} <br /> {price} Ar/jr
        </Popup>
      </Marker>

    </MapContainer>);
}

export default Map; 