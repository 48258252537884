import React, { } from "react";

import styled from 'styled-components';
import tw from 'twin.macro';


const Body = styled.div`
  ${tw`p-4`}
`;

const Backdrop = styled.div`
  ${tw`fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-50 z-10`}
`;
const Container = styled.div`
  ${tw`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg z-20`}
  width: 90%;
  max-width: 600px;
`;


const TelmaModal = ({ isOpen, onClose }) => {
    // const stripePromise = loadStripe('pk_test_51MqzCQKdA66FiBn3StVaYDusHDZkog9PpZGNFssVnpc5xsi9GmJL7DMpR9w6yEQjCyqSMkKj76EGwVBlTR1lDKTA00AiYBoOOo');

    if (!isOpen) {
        return null;
    }

    return (
        <Backdrop onClick={onClose}>
            <Container>
                <Body>
                    <section>
                        <div className='product'>
                            <img
                                src='https://tse4.explicit.bing.net/th?id=OIP.fuqHgSGADXWcQjoiUHKeqQHaEc'
                                alt='Payement by mvola'
                            />
                            <div className='description'>
                                <h3>Payement mvola se fait par transfert vers compte au nom de Mr PATRICE, C.E.O Elite401</h3>
                                <h5>034 </h5>
                            </div>
                        </div>
                    </section>
                </Body>
            </Container>
        </Backdrop>
    );

};

export default TelmaModal;
