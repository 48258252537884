import { ProductContext, ProductDispath } from "Context/ContextProvider";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import tw from 'twin.macro';



const Backdrop = styled.div`
  ${tw`fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-50 z-10`}
`;
const Container = styled.div`
  ${tw`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg z-20`}
  width: 90%;
  max-width: 600px;
`;
const InputContainer = tw.div`relative py-5 mt-1`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm m-8`;
const Input = tw.input`bg-gray-100 h-12 m-8 px-4`;
const SubmitButton = tw.button`w-full mb-8 my-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

function Pay() {
    const [amount, setAmount] = useState(0);

    const [amountAr, setAmountAr] = useState(0);
    const { dispath } = useContext(ProductDispath);

    const navigate = useNavigate();

    const clicked = async () => {
        dispath({ type: "SET_AMOUNT_TO_PAY", payload: amount })
        navigate("/stripe/payment");
    }
    function clickHandler(e) {
        setAmount(e.target.value)
        setAmountAr(parseFloat(e.target.value) * 4200)
    }

    return (
        <>
            <Backdrop >
                <Container>

                    <InputContainer>
                        <Label htmlFor="name-input">Montant en euro</Label>
                        <Input
                            type="text"
                            value={amount}
                            onChange={clickHandler}
                        />


                    </InputContainer>
                    <InputContainer>
                        <Label htmlFor="name-input">Correspondance en ariary</Label>
                        <Input
                            type="text"
                            value={amountAr}
                            disabled

                        />
                    </InputContainer>
                    <SubmitButton onClick={clicked}> Suivant</SubmitButton>



                </Container>
            </Backdrop>
        </>
    );
}

export default Pay;