import React, { useContext, useEffect, useState } from "react";
import { createBreakpoint } from 'react-use';
import tw from "twin.macro";
import { motion } from "framer-motion";
import { css } from "styled-components/macro"; //eslint-disable-line
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as HomeIcon } from "feather-icons/dist/icons/home.svg";
// import { ReactComponent as DodoIcon } from "feather-icons/dist/icons/users.svg";


import SearchBar from "components/SearchBar/SearchBar";
import { ProductDispath, ProductContext } from "../../../Context/ContextProvider";
import { GET_TRANO_API_WITH_CATEGORIES, GET_BOOKED_TRANO_API_WITH_CATEGORIES } from "helpers/api";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const HeadingWithControl = styled.div`
  ${tw`flex border-b border-gray-200 flex-col items-center overflow-x-clip sm:overflow-x-visible sm:items-stretch sm:flex-row justify-between`}
  @media (max-width: 640px) {
    margin-inline: 2rem;
  }

  @media (max-width: 480px) {
    margin-inline: 2rem;
  }
  `;

const Container = tw.div`relative mx-[-2rem]`;
const CardContainer = tw(motion.div)`lg:flex md:flex flex-wrap`;
const Content = tw.div`max-w-screen-xl mx-auto py-4 lg:py-5`;

const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-full h-48 bg-center bg-cover relative rounded-b-xl rounded-t-xl`}
`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;
const TextInfo = tw.div`bg-gray-100 p-2`;
const Title = tw.h5`font-bold overflow-hidden overflow-ellipsis whitespace-nowrap`;
const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4 overflow-hidden overflow-ellipsis whitespace-nowrap`;
const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row sm:justify-between mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-1 sm:my-0`;
const IconContainer = styled.div`
${tw`inline-block rounded-full p-1 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-2 h-2`}
  }
`;
// const DodoContainer = styled.div`
//   ${tw`inline-block rounded-full ml-1 text-gray-700`}
//   svg {
//     ${tw`w-6 h-4`}
//   }
// `;
const Text = tw.div`ml-2 text-xs font-semibold text-gray-800`;

export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const [isAddToBasket, setIsAddToBasket] = useState(false);
  const useBreakpoint = createBreakpoint({ XL: 1280, L: 768, S: 350 });
  const breakpoint = useBreakpoint();
  let Card = tw.div`pr-4 pt-4 md:w-1/3 lg:w-1/4`;

  const { state } = useContext(ProductContext);
  const { dispath } = useContext(ProductDispath);
  const productsList = state.filteredItems;
  const params = useParams();

  const loadData = async () => {

    let res = await GET_TRANO_API_WITH_CATEGORIES((params.category).toUpperCase());
    dispath({ type: "LOAD_TRANO", payload: res.data });

    let res_ = await GET_BOOKED_TRANO_API_WITH_CATEGORIES((params.category).toUpperCase());
    dispath({ type: "LOAD_BOOKED_TRANO", payload: { "booked": res_.data, "trano": res.data } });
  }
  const formatPrice = (price) => {
    return price.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }
  if (breakpoint === 'S') {
    Card = tw.div`p-4 m-4 md:w-1/3 lg:w-1/4`;
  }

  useEffect(() => {
    debugger
    !state.dataLoaded &&
      loadData()
    // console.log(state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])


  return (
    <Container>
      <ToastContainer />
      <Content>
        <HeadingWithControl>
          <SearchBar />
        </HeadingWithControl>
        <CardContainer>
          {
            state.dataLoaded && productsList.length == 0 &&
            <Text>
              <br />
              Pas de maison disponible.
              <br />
              Merci de trouver autre date.
              <br />
            </Text>
          }
          {productsList.length > 0 && (
            productsList.map((card, index) => (
              // {cards.map((card, index) => (
              <Card key={index}>
                <Link to={`/trano/details/${card.id}`}>
                  <CardImageContainer imageSrc={card.images}>
                    <CardRatingContainer>
                      <CardRating>
                        <PriceIcon />
                        {formatPrice(parseInt(card.price))} Ar/ jr
                      </CardRating>
                    </CardRatingContainer>
                  </CardImageContainer>

                </Link>
                <TextInfo>
                  {/* <TitleReviewContainer> */}
                  <Title>{card.name}</Title>
                  <SecondaryInfoContainer>
                    <IconWithText>
                      <IconContainer>
                        <LocationIcon />
                      </IconContainer>
                      <Text>{card.destination}</Text>
                    </IconWithText>
                    <IconWithText>
                      <IconContainer>
                        <HomeIcon />
                      </IconContainer>
                      <Text>{card.category}</Text>
                      {/* <DodoContainer>
                        <DodoIcon />
                      </DodoContainer>
                      <Text>{card.max_pers} Pers</Text> */}
                    </IconWithText>
                  </SecondaryInfoContainer>

                  <Description>{card.description}</Description>
                </TextInfo>
                {/* <PrimaryButton onClick={e => handelClick(e, card.id)}>Reserver</PrimaryButton> */}
              </Card>
            ))
          )
          }
        </CardContainer>
      </Content>
    </Container>
  );
};
