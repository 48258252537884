import React, { useContext, useEffect, useState } from "react";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/signup-illustration.svg";
import macHeroScreenshotImageSrc from "images/capture.png";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";

import { ProductContext } from "Context/ContextProvider";
import { REGISTER_API } from "helpers/api";
import { Navigate } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'



const Container = tw(ContainerBase)`min-h-screen bg-white text-white font-medium flex justify-center -mr-8 -ml-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 bg-white text-gray-900 sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-2 sm:p-12`;

const MainContent = tw.div`mt-4 flex flex-col items-center`;
const Heading = tw.h5`text-xl font-extrabold text-center`;
const FormContainer = tw.div`w-full flex-1`;
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputDiv = tw.div`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;

const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`m-3 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .text {
    ${tw`ml-3`}
  }
`;
const CancelButton = styled.button`
  ${tw`m-3 tracking-wide font-semibold bg-red-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

export default ({
  logoLinkUrl = "#",
  illustrationImageSrc = macHeroScreenshotImageSrc,
  headingText = "Sign Up For Treact",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Sign Up With Google",
      url: "https://google.com"
    },
    {
      iconImageSrc: twitterIconImageSrc,
      text: "Sign Up With Twitter",
      url: "https://twitter.com"
    }
  ],
  submitButtonText = "Sign Up",
  SubmitButtonIcon = SignUpIcon,
  tosUrl = "#",
  privacyPolicyUrl = "#",
  signInUrl = "/login"
}) => {

  const { state } = useContext(ProductContext);
  const [activationLink, setActivationLink] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState()


  const handleSubmit = async (event) => {
    //Prevent page reload
    event.preventDefault();
    setIsLoading(true);


    var { name, email, ville, address } = document.forms[0];

    // let p = phone.value.replace(/[^0-9]/g, '');
    let phone_umber = phoneNumber.replace('+', '');
    if (phone_umber.length !== 12) {
      setIsLoading(false);
      toast.error("Veuillez verifier numero tel!")
      return
    }
    // let code = p.substr(0, 4)
    // if (code !== "2613") {
    //   setIsLoading(false);
    //   toast.error("Veuillez verifier numero tel!")
    //   return
    // }


    let playload = {
      name: name.value,
      email: email.value,
      ville: ville.value,
      address: address.value,
      phone_number: phone_umber,
    }

    // pass = pass.value
    try {
      let res = await REGISTER_API(playload)
      console.log(res)
      if (res.status === 200) {
        setActivationLink(res.data.activation)
      }
      else {
        toast.error("Veuillez verifier bien!")
      }
      setIsLoading(false)

    } catch (error) {
      setIsLoading(false)
      // console.log(error);
      toast.error(error)
    }
  };

  const CheckYourmail = () => (
    <>
      Nous avons envoyé liens d'activation et d'ajout de mot de passe pour votre compte.
      Veuillez vérifier votre email y parmis dossier spam et cliquez sur le boutton activation.
    </>
  );

  useEffect(() => {

  }, [])


  return (
    <>
      {
        state.user ?
          <Navigate to="/profile" /> :
          <AnimationRevealPage>
            <Header />
            <Container >
              <ToastContainer />

              <Content>

                <IllustrationContainer>
                  <IllustrationImage imageSrc={illustrationImageSrc} />
                </IllustrationContainer>
                <MainContainer>
                  <MainContent>
                    <FormContainer>
                      <br />
                      {
                        activationLink ?
                          <CheckYourmail /> :
                          <form onSubmit={handleSubmit}>
                            <>
                              <Heading>
                                Creation compte
                              </Heading>
                              <Input type="text" name="name" required placeholder="Rabe" />
                              <Input type="email" name="email" required placeholder="mail@trano-vacance.mg" />
                              {/* <Input type="text" name="phone" required placeholder="261340555510" /> */}
                              <InputDiv>
                                <PhoneInput international name="phone2" value={phoneNumber}
                                  countryCallingCodeEditable={false}
                                  defaultCountry="MG"
                                  onChange={setPhoneNumber} />
                              </InputDiv>
                              <Input type="text" name="ville" required placeholder="Antananarivo" />
                              <Input type="text" name="address" required placeholder="II A Cite Itaosy" />

                              <TwoColumn>
                                <CancelButton type="button" onClick={() => Navigate("/")}>
                                  {isLoading && <FadeLoader height={6} color="#36d7b7" cssOverride={{ height: '1.5rem', top: '0.5rem' }} />}
                                  <span className="text">Cancel</span>
                                </CancelButton>

                                <SubmitButton type="submit">
                                  {isLoading && <FadeLoader height={6} color="#36d7b7" cssOverride={{ height: '1.5rem', top: '0.5rem' }} />}
                                  <span className="text">Enregistrer</span>
                                </SubmitButton>
                              </TwoColumn>
                            </>
                          </form>
                      }
                    </FormContainer>

                    <p tw="mt-8 text-sm text-gray-600 text-center">
                      <a href={signInUrl} tw="border-b border-gray-500 border-dotted">
                        Si vous avez un compte. Cliquez ici pour se connecter
                      </a>
                    </p>

                  </MainContent>
                </MainContainer>

              </Content>
            </Container >
            <Footer />
          </AnimationRevealPage>
      }
    </>

  )
}
