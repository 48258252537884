import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { FOOTER_A_PROPOS } from "helpers/soratra";
import { motion } from "framer-motion";
// const Container = tw.div`relative bg-blue-800 text-white -mb-8 -mx-8 px-8 py-8 z-0`;
const Container = tw.div`relative bg-white font-black -mb-8 -mx-8 px-8 py-8 z-0`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;
const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;
const ColumnHeading = tw.h5`uppercase font-bold`;
const LinkList = tw.ul`mt-6 text-base font-medium`;
const LinkListItem = tw.li`mt-2`;
const Image = styled(motion.div)(props => [
  `background-image: url("${props.$imageSrc}");`,
  tw`h-8   bg-cover bg-center rounded`
]);
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;
// const SubscribeNewsletterColumn = tw(Column)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`;
// const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
// const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-600`;
// const SubscribeForm = tw.form`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
// const Input = tw.input`bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
// const SubscribeButton = tw(PrimaryButtonBase)`mt-4 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3`;
const Divider = tw.div`my-4 border-b-2 border-gray-300 w-full`;
const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;
const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;
const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-primary-500 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
// const SocialLink = styled.a`
//   ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
//   svg {
//     ${tw`w-4 h-4`}
//   }
// `;
export default () => {
  const currentYear = new Date().getFullYear();
  return (
    <Container>
      <Content>
        <SixColumns>
          <Column>
            <ColumnHeading>{FOOTER_A_PROPOS}</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link>NIF: 2011930059</Link>
              </LinkListItem>
              <LinkListItem>
                <Link>STATS: 59202412022000949</Link>
              </LinkListItem>
              <LinkListItem>
                <Link >ADDRESSE: 0105C0244 Mangarivotra - Mahajanga</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Contact & Support</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link>PHONE: 032 67 315 81 & 034 21 775 10</Link>
              </LinkListItem>

              <LinkListItem>
                <Link>PAGE FB: Trano-vacance.mg</Link>
              </LinkListItem>
              <LinkListItem>
                <Link>WHATSAPP: +261 34 21 775 10</Link>
              </LinkListItem>
            </LinkList>
          </Column>

          <Column>
            <ColumnHeading>Liens</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/">Accueil</Link>
              </LinkListItem>

              <LinkListItem>
                <Link href="/conditions">Conditions de location</Link>
              </LinkListItem>

              {/* <LinkListItem>
                <Link href="#">Annuaire Guides Touristiques</Link>
              </LinkListItem> */}

            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Paiement</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Image $imageSrc={"/logo-om.png"} />
              </LinkListItem>
              <LinkListItem>
                <Image $imageSrc={"/logo-mvola.png"} />
              </LinkListItem>
              <LinkListItem>
                <Image $imageSrc={"/logo-stripe.png"} />
              </LinkListItem>
            </LinkList>
          </Column>
          {/* <SubscribeNewsletterColumn>
            <SubscribeNewsletterContainer>
              <ColumnHeading>Subscribe to our Newsletter</ColumnHeading>
              <SubscribeText>
                Pour avoir les nouvelles informations sur les maisons disponibles sur notre site.
              </SubscribeText>
              <SubscribeForm method="get" action="#">
                <Input type="email" placeholder="Your Email Address" />
                <SubscribeButton type="submit">Subscribe</SubscribeButton>
              </SubscribeForm>
            </SubscribeNewsletterContainer>
          </SubscribeNewsletterColumn> */}
        </SixColumns>
        <Divider />
        <ThreeColRow>
          <CopywrightNotice>&copy; 2021- {currentYear} Trano-vacance.MG All Rights Reserved.</CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink href="https://web.facebook.com/profile.php?id=100086740967169">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
};
