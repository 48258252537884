import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import FAQ from "./components/SingleCol.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { FAQ_TITRE_DESC } from "helpers/soratra.js";

export default () => {
    const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
    const HighlightedText = tw.span`text-primary-500`;

    return (
        <AnimationRevealPage>
            <Header />
            <FAQ
                description ={FAQ_TITRE_DESC}
                heading={
                    <>
                        Conditions de <HighlightedText>location</HighlightedText>
                    </>
                }
                faqs={[
                    {
                      question: "Paiement des réservations",
                      answer:
                        "50% du montant total de la réservation sera versé lors de la réservation, et cela peut se faire sur ce site."
                    },
                    // {
                    //   question: "Penalité",
                    //   answer:
                    //     "Afin qu'il y ait respect mutuel entre locataires et propriétaires, il y a une sanction en cas d'abus."
                    // },
                    {
                      question: "Responsabilités du locataire",
                      answer:
                        "Suivez les règles établies par les hôtes s'il y en a telles que la propreté..."
                    },
                    {
                      question: "Notre responsabilité",
                      answer:
                        // eslint-disable-next-line no-multi-str
                        "Vérifier toutes les informations sur la maison à louer.\
                        Vérifier les abus du propriétaire ou du locataire. \
                        Nous prenons en charge tous les aspects techniques pour faciliter et accélérer votre réservation."
                    },
                    {
                      question: "Responsabilités du propriétaire",
                      answer:
                        "Propriétaire est obligé de garder les chambres réservées par le client disponibles et de fournir les services convenus."
                    },
                    {
                      question: "Modification des dates",
                      answer:
                        "Si la maison est encore libre au moment de la nouvelle date de changement, des modifications peuvent être apportées."
                    },
                    {
                      question: "Réduire les dates",
                      answer:
                        "Il est également possible de réduire la date, mais si le montant est superieur à l'avance, l'argent ne sera pas remboursé."
                    },
                    {
                      question: "Annulation des dates",
                      answer:
                        "Il n'est pas possible de restituer l'argent qui a déjà été dépensé. Vérifiez donc bien si la date choisie ou le lieu où vous rendre, nous ne sommes pas responsables."
                    },
                    // {
                    //   question: "Sanctions pour les propriétaires",
                    //   answer:
                    //     "S'il est confirmé qu'il y a abus de la part du propriétaire, le locataire peut partir et le propriétaire remboursera son loyer pour aller voir ailleurs."
                    // },
                    // {
                    //   question: "Sanction aux locataires",
                    //   answer:
                    //     "Si le locataire est reconnu coupable d'abus, il sera expulsé de la propriété qu'il a réservée. Il n'y a aucun remboursement."
                    // },
    
                  ]}
            />

            <Footer />
        </AnimationRevealPage>
    );
}
