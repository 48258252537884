import React, { useContext, useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import macHeroScreenshotImageSrc from "images/capture.png";

import { ProductContext, ProductDispath } from "Context/ContextProvider";
import { LOGIN_API, RESEND_MAIL_API } from "helpers/api";
import { Navigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FadeLoader from "react-spinners/FadeLoader";
import { LOGIN_OUVRIR_COMPTE } from "helpers/soratra";

const Container = tw(ContainerBase)`min-h-screen bg-white text-white font-medium flex justify-center -mr-8 -ml-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900  sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const MainContent = tw.div`mt-6 flex flex-col items-center`;
const Heading = tw.h1`text-2xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1  text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

export default ({
  illustrationImageSrc = macHeroScreenshotImageSrc,
  headingText = "Reset mot de passe",
  submitButtonText = "Envoie",
  signInUrl = "/login"

}) => {

  const [activationLink, setActivationLink] = useState()
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitEmail = async (event) => {
    //Prevent page reload
    event.preventDefault();
    setIsLoading(true);

    var { email, } = document.forms[0];
    let playload = {
      email: email.value,
    }

    try {
      let res = await RESEND_MAIL_API(playload)
      console.log(res)
      if (res.status === 200) {
        setActivationLink(res.data.activation)
      }
      else {
        toast.info("Essaye encore!")
      }
      setIsLoading(false)

    } catch (error) {
      setIsLoading(false)
      // console.log(error);
      toast.error(error.message)
    }
  };

  useEffect(() => {

  }, [])

  return (
    <>

      {

        <AnimationRevealPage>
          <Header />
          <ToastContainer />
          <Container>
            <Content>
              <IllustrationContainer>
                <IllustrationImage imageSrc={illustrationImageSrc} />
              </IllustrationContainer>
              <MainContainer>
                <MainContent>
                  <Heading>{headingText}</Heading>
                  <p tw="mt-6 text-xs text-gray-600 ">
                    Pour recevoir liens de reset password
                  </p>
                  {
                    activationLink ?
                      <p tw="mt-6 text-xs text-gray-600 ">
                        Veuillez voir votre boite email
                      </p>
                      :
                      <FormContainer>
                        <Form onSubmit={handleSubmitEmail}>
                          <Input required type="email" name="email" placeholder="admin@trano-vacance.mg" />
                          <SubmitButton type="submit">
                            {isLoading && <FadeLoader height={6} color="#36d7b7" cssOverride={{ height: '1.5rem', top: '0.5rem' }} />}
                            <span className="text">{submitButtonText}</span>
                          </SubmitButton>
                        </Form>
                      </FormContainer>
                  }
                </MainContent>
              </MainContainer>

            </Content>
          </Container>
          <Footer />
        </AnimationRevealPage>
      }

    </>
  )
};
