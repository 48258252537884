import React, { useContext } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";
import { ProductContext } from "../../Context/ContextProvider";
import styled from "styled-components"; //eslint-disable-line

const Container = tw(ContainerBase)`text-gray-100 -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(SectionDescription)`text-gray-400 text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md justify-between mx-auto`
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`
const StatKey = tw.div`text-xl font-medium`
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`
const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;
export default ({
  subheading = "",
  heading = "Over 9000 Projects Completed",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  stats = [
    {
      key: "Clients",
      value: "2500+",
    },
    {
      key: "Revenue",
      value: "$100M+",
    },
    {
      key: "Employees",
      value: "150+",
    },
  ]
}) => {
  const { state } = useContext(ProductContext);

  return (
    <Container>
      <ContentWithPaddingXl>
        <FormContainer>
          <HeadingContainer>
            <Heading>Mes informations</Heading>
            <Description>ny Kaontinao</Description>
          </HeadingContainer>
          <StatsContainer>
            <Stat>
              <StatValue>{state.user.profile_id}</StatValue>
              <StatKey>Profile</StatKey>
            </Stat>
            <Stat>
              <StatValue>{state.user.address}</StatValue>
              <StatKey>Addresse</StatKey>
            </Stat>
            <Stat>
              <StatValue>{state.user.phone_number}</StatValue>
              <StatKey>Numero</StatKey>
            </Stat>
            <Stat>
              <StatValue>{state.user.mobile_money}</StatValue>
              <StatKey>Solde</StatKey>
            </Stat>
          </StatsContainer>
        </FormContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
