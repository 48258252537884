import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import SearchBar from "components/SearchBar/SearchBar";
import { ProductDispath, ProductContext } from "../../Context/ContextProvider";
import { GET_BOOKED_TRANO_API, GET_TRANO_API } from "helpers/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-4 lg:py-5`;
const HeadingWithControl = tw.div`flex border-b border-gray-200 flex-col items-center sm:items-stretch sm:flex-row justify-between`;
// const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const Card = tw.div`mr-2 mt-4 sm:w-2/3 md:w-1/3 lg:w-1/4`;
// const Card = tw.div`h-full flex! p-4 flex-col max-w-sm relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-32 sm:h-48 bg-cover bg-center rounded-b-xl rounded-t-xl`
]);
const TextInfo = tw.div`py-3 sm:px-5 sm:py-3`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`font-bold`;
const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;
const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;
const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row sm:justify-between mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-xs font-semibold text-gray-800`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none  py-3 sm:py-6`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;


export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const [isAddToBasket, setIsAddToBasket] = useState(false);

  const handelClick = (e, id) => {
    e.preventDefault()
    toast.info("Aza adino ny misafidy daty mialoha ny reservation!")
    toast.info("ao anaty liste reservation etsy ambony ny manao confirmation!")
    dispath({ type: "ADD_TO_BASKET", payload: id });
    setIsAddToBasket(true);

    setTimeout(() => {
      setIsAddToBasket(false);
    }, 3000);
  };
  const { state } = useContext(ProductContext);
  const { dispath } = useContext(ProductDispath);
  const productsList = state.filteredItems;

  const loadData = async () => {
    let res = await GET_TRANO_API();
    dispath({ type: "LOAD_TRANO", payload: res.data });
    let res_ = await GET_BOOKED_TRANO_API();
    dispath({ type: "LOAD_BOOKED_TRANO", payload: { "booked": res_.data, "trano": res.data } });
  }



  useEffect(() => {
    !state.dataLoaded &&
      loadData()
    console.log(state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <Container>
      <ToastContainer />
      <Content>
        <HeadingWithControl>
          <SearchBar />
        </HeadingWithControl>
        <>
          {productsList.length > 0 && (
            productsList.map((card, index) => (
              // {cards.map((card, index) => (
              <Card key={index}>
                <Link to={`/${card.id}`}>
                  <CardImage imageSrc={card.images} />
                </Link>
                <TextInfo>
                  {/* <TitleReviewContainer> */}
                  <Title>{card.name}</Title>
                  {/* <RatingsInfo>
                      <StarIcon />
                      <Rating>5</Rating>
                      <Rating>{card.rating} 5</Rating> 
                    </RatingsInfo> */}


                  {/* </TitleReviewContainer> */}
                  <SecondaryInfoContainer>
                    <IconWithText>
                      <IconContainer>
                        <LocationIcon />
                      </IconContainer>
                      <Text>Mahajanga</Text>
                    </IconWithText>
                    <IconWithText>
                      <IconContainer>
                        <PriceIcon />
                      </IconContainer>
                      <Text>{card.price} Ar/ jr</Text>
                    </IconWithText>
                  </SecondaryInfoContainer>
                  <Description>{card.description}</Description>
                </TextInfo>
                {/* <PrimaryButton onClick={e => handelClick(e, card.id)}>Reserver</PrimaryButton> */}
              </Card>
            ))
          )
          }
        </>
      </Content>
    </Container>
  );
};
