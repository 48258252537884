import React, { useContext, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Slider from "react-slick";
import BookingCalendar from 'react-booking-calendar';
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import StatsIllustrationSrc from "images/stats-illustration.svg";
import { ProductDispath, ProductContext } from "../../../Context/ContextProvider";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faPuzzlePiece, faUsers, faUtensils, faVideo, faWifi } from '@fortawesome/fontawesome-free-solid';
import Map from "./Map.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PrimaryButton } from "components/misc/Buttons.js";
import "slick-carousel/slick/slick.css";
import SearchBar from "components/SearchBar/SearchBar";
import ReactModalAdapter from "helpers/ReactModalAdapter.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { faFan, faHotTub, faMugSaucer, faPersonSwimming, faPizzaSlice, faSpa, faSquareParking } from "@fortawesome/free-solid-svg-icons";
import ReactRating from "react-rating";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import ReviewList from "./ReviewList";


// const Container = tw.div`relative bg-gray-10 mx-[-2rem] `;
const Container = tw.div`relative  mx-[-2rem] `;
const Content = tw.div`max-w-screen-xl mx-auto py-3 `;
const TestimonialsContainer = tw.div`px-8 mt-[1rem] bg-gray-100 rounded-xl`;
const Testimonials = styled.div``;
const Testimonial = tw.div`max-w-md lg:max-w-none mx-auto lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row`;
const TestimonialImageSlider = tw(Slider)`w-full lg:w-5/12 flex-shrink-0 `;
const ImageAndControlContainer = tw.div`mt-8 relative outline-none`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-80`
]);
const CardBookingCalendar = styled(BookingCalendar)`
.primaryAction {
  ${tw`bg-primary-500 text-gray-100 hover:bg-primary-700 cursor-pointer`}
}
`;
const ControlContainer = tw.div`absolute bottom-0 right-0 bg-gray-100 px-6 py-4 rounded-tl-3xl border`;
const ControlButton = styled(PrimaryButton)`
  ${tw`mx-3 rounded-full text-gray-100 p-2`}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const TextContainer = styled.div(props => [
  tw`flex flex-col w-full lg:w-7/12 `,
  props.textOnLeft ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last`
]);
const Actions = styled.div`
  ${tw`lg:mb-0`}
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-3 text-sm font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300 cursor-pointer`}
  }
  .primaryAction {
    ${tw`bg-primary-500 text-gray-100 hover:bg-primary-700`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800 `}
  }`
const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-4/5 lg:p-8`}
  }
  .contentcalendar {
    ${tw`lg:w-1/2 lg:p-16 md:w-1/2 w-full`}
  }
`;

const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

const Subheading = tw(SubheadingBase)`mt-4 mb-4 text-base`;
const Heading = tw(SectionHeading)`font-black text-left text-xl text-center md:text-left leading-tight`;
const Description = tw.p`max-w-md text-xs text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed sm:text-base mt-4 text-secondary-100`;
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Extra = tw.p`max-w-md text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base font-medium mt-4 text-secondary-100`;

const HeadingWithControl = styled.div`
  ${tw`flex border-b border-gray-200 flex-col items-center overflow-x-clip sm:overflow-x-visible sm:items-stretch sm:flex-row justify-between`}
  @media (max-width: 640px) {
    margin-inline: 2rem;
  }

  @media (max-width: 480px) {
    margin-inline: 2rem;
  }
  `;
const ThreColumns = tw.div`max-w-screen-xl mx-auto py-4  flex flex-wrap justify-between`;
const ColumnHeading = tw.h6`text-xs font-bold pb-2`;
const ColumnHeadingLine = tw.h6`text-xs font-bold pb-2 line-through`;

const Column = tw.div`md:w-1/3 pb-3 pr-4 w-full `;

// const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;

export default ({
  subheading = "Our Track Record",
  heading = (
    <>
      We have been doing this <wbr /> since <span tw="text-primary-500">1999.</span>
    </>
  ),
  category = "Appartement",
  destination = "Mahajanga",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  extra = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  primaryButtonText = "Learn More",
  imageSrc = StatsIllustrationSrc,
  imageSrc_outdoor = StatsIllustrationSrc,
  imageSrc_indoor = StatsIllustrationSrc,
  rating = 2.5,
  booked = [],
  textOnLeft = false,
  statistics,
  id,
  longitude,
  latitude,
}) => {
  const defaultStatistics = [
    {
      key: "Clients",
      value: "2282+"
    },
    {
      key: "Projects",
      value: "3891+"
    },
    {
      key: "Awards",
      value: "1000+"
    }
  ];

  if (!statistics) statistics = defaultStatistics;
  const { dispath } = useContext(ProductDispath);
  const { state } = useContext(ProductContext);
  const [isAddToBasket, setIsAddToBasket] = useState(false);
  const [imageSliderRef, setImageSliderRef] = useState(null);
  const [textSliderRef, setTextSliderRef] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [componentId, setComponentId] = useState(1);

  const toggleModal = (i) => {
    setModalIsOpen(!modalIsOpen);
    setComponentId(i)
  }

  const handelClick = () => {

    if (state.basket.length > 0) {
      toast.info("Il faut annuler ou confirmer la sélection en cours avant de faire nouvelle réservation ")
    } else {
      dispath({ type: "ADD_TO_BASKET", payload: id });
      toast.info("Merci de bien vérifier la date, la capacité de la maison.")
      setIsAddToBasket(true);

      setTimeout(() => {
        setIsAddToBasket(false);
      }, 3000);
    }

  };

  const formatPrice = (price) => {
    return price.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }


  return (
    <Container>
      <Content>
        <ToastContainer position="bottom-right" />
        <HeadingWithControl>
          <SearchBar />
        </HeadingWithControl>
        <TestimonialsContainer>
          <Testimonials>
            <Testimonial>
              <TestimonialImageSlider arrows={false} ref={setImageSliderRef} asNavFor={textSliderRef} fade={true}>

                <ImageAndControlContainer key={0}>
                  <Image imageSrc={imageSrc} />
                  <ControlContainer>
                    <ControlButton onClick={imageSliderRef?.slickPrev}>
                      <ChevronLeftIcon />
                    </ControlButton>
                    <ControlButton onClick={imageSliderRef?.slickNext}>
                      <ChevronRightIcon />
                    </ControlButton>
                  </ControlContainer>
                </ImageAndControlContainer>
                <ImageAndControlContainer key={1}>
                  <Image imageSrc={imageSrc_outdoor} />
                  <ControlContainer>
                    <ControlButton onClick={imageSliderRef?.slickPrev}>
                      <ChevronLeftIcon />
                    </ControlButton>
                    <ControlButton onClick={imageSliderRef?.slickNext}>
                      <ChevronRightIcon />
                    </ControlButton>
                  </ControlContainer>
                </ImageAndControlContainer>
                <ImageAndControlContainer key={2}>
                  <Image imageSrc={imageSrc_indoor} />
                  <ControlContainer>
                    <ControlButton onClick={imageSliderRef?.slickPrev}>
                      <ChevronLeftIcon />
                    </ControlButton>
                    <ControlButton onClick={imageSliderRef?.slickNext}>
                      <ChevronRightIcon />
                    </ControlButton>
                  </ControlContainer>
                </ImageAndControlContainer>
              </TestimonialImageSlider>
              <TextContainer textOnLeft={textOnLeft}>
                <TextContent>
                  <Heading>
                    {subheading} - {destination}
                  </Heading>
                  <Subheading>{category}  {formatPrice(parseInt(heading))} Ar / jour
                  </Subheading>
                  <ReactRating
                    readonly
                    placeholderRating={Number(rating)}
                    emptySymbol={<StarIcon style={{ color: "beige" }} />}
                    placeholderSymbol={<StarIcon style={{ color: "beige", fill: "beige" }} />}
                  />
                  {rating} sur 5
                  <Description>{description}</Description>

                  <Extra>{extra}</Extra>

                  <ThreColumns>
                    {statistics.map((statistic, index) => (
                      <Column key={index}>
                        {
                          statistic.id === 1
                          &&
                          <ColumnHeading>
                            <FontAwesomeIcon size='1x' icon={faBed} />
                            {" "}{statistic.value}{" "}{statistic.key}
                          </ColumnHeading>
                        }
                        {
                          statistic.id === 2
                          &&

                          <ColumnHeading>
                            <FontAwesomeIcon size='1x' icon={faUsers} />
                            {" "}{statistic.value}{" "}{statistic.key}
                          </ColumnHeading>

                        }
                        {
                          statistic.id === 3
                          && (statistic.value ?
                            <ColumnHeading>
                              <FontAwesomeIcon size='1x' icon={faVideo} />
                              {" "}{statistic.key}
                            </ColumnHeading>
                            :
                            <ColumnHeadingLine>
                              <FontAwesomeIcon size='1x' icon={faVideo} />
                              {" "}{statistic.key}
                            </ColumnHeadingLine>
                          )
                        }
                        {
                          statistic.id === 4
                          && (statistic.value ?
                            <ColumnHeading>
                              <FontAwesomeIcon size='1x' icon={faUtensils} />
                              {" "}{statistic.key}
                            </ColumnHeading>
                            :
                            <ColumnHeadingLine>
                              <FontAwesomeIcon size='1x' icon={faUtensils} />
                              {" "}{statistic.key}
                            </ColumnHeadingLine>
                          )
                        }
                        {
                          statistic.id === 5
                          && (statistic.value ?
                            <ColumnHeading>
                              <FontAwesomeIcon size='1x' icon={faWifi} />
                              {" "}{statistic.key}
                            </ColumnHeading>
                            :
                            <ColumnHeadingLine>
                              <FontAwesomeIcon size='1x' icon={faWifi} />
                              {" "}{statistic.key}
                            </ColumnHeadingLine>
                          )
                        }
                        {
                          statistic.id === 6
                          && (statistic.value ?
                            <ColumnHeading>
                              <FontAwesomeIcon size='1x' icon={faFan} />
                              {" "}{statistic.key}
                            </ColumnHeading>
                            :
                            <ColumnHeadingLine>
                              <FontAwesomeIcon size='1x' icon={faFan} />
                              {" "}{statistic.key}
                            </ColumnHeadingLine>
                          )
                        }
                        {
                          statistic.id === 7
                          && (statistic.value ?
                            <ColumnHeading>
                              <FontAwesomeIcon size='1x' icon={faMugSaucer} />
                              {" "}{statistic.key}
                            </ColumnHeading>
                            :
                            <ColumnHeadingLine>
                              <FontAwesomeIcon size='1x' icon={faMugSaucer} />
                              {" "}{statistic.key}
                            </ColumnHeadingLine>
                          )
                        }
                        {
                          statistic.id === 8
                          && (statistic.value ?
                            <ColumnHeading>
                              <FontAwesomeIcon size='1x' icon={faPizzaSlice} />
                              {" "}{statistic.key}
                            </ColumnHeading>
                            :
                            <ColumnHeadingLine>
                              <FontAwesomeIcon size='1x' icon={faPizzaSlice} />
                              {" "}{statistic.key}
                            </ColumnHeadingLine>
                          )
                        }
                        {
                          statistic.id === 9
                          && (statistic.value ?
                            <ColumnHeading>
                              <FontAwesomeIcon size='1x' icon={faPersonSwimming} />
                              {" "}{statistic.key}
                            </ColumnHeading>
                            :
                            <ColumnHeadingLine>
                              <FontAwesomeIcon size='1x' icon={faPersonSwimming} />
                              {" "}{statistic.key}
                            </ColumnHeadingLine>
                          )
                        }
                        {
                          statistic.id === 10
                          && (statistic.value ?
                            <ColumnHeading>
                              <FontAwesomeIcon size='1x' icon={faSpa} />
                              {" "}{statistic.key}
                            </ColumnHeading>
                            :
                            <ColumnHeadingLine>
                              <FontAwesomeIcon size='1x' icon={faSpa} />
                              {" "}{statistic.key}
                            </ColumnHeadingLine>
                          )
                        }
                        {
                          statistic.id === 11
                          && (statistic.value ?
                            <ColumnHeading>
                              <FontAwesomeIcon size='1x' icon={faSquareParking} />
                              {" "}{statistic.key}
                            </ColumnHeading>
                            :
                            <ColumnHeadingLine>
                              <FontAwesomeIcon size='1x' icon={faSquareParking} />
                              {" "}{statistic.key}
                            </ColumnHeadingLine>
                          )
                        }
                        {
                          statistic.id === 12
                          && (statistic.value ?
                            <ColumnHeading>
                              <FontAwesomeIcon size='1x' icon={faHotTub} />
                              {" "}{statistic.key}
                            </ColumnHeading>
                            :
                            <ColumnHeadingLine>
                              <FontAwesomeIcon size='1x' icon={faHotTub} />
                              {" "}{statistic.key}
                            </ColumnHeadingLine>
                          )
                        }

                      </Column>
                    ))}
                  </ThreColumns>

                  <Actions>
                    <span className="action primaryAction"
                      onClick={handelClick}>
                      {primaryButtonText}
                    </span>
                    <span className="action secondaryAction"
                      onClick={() => toggleModal(1)}>
                      Voir dates disponibles
                    </span>
                    <span className="action secondaryAction"
                      onClick={() => toggleModal(2)}>
                      Voir carte localisation
                    </span>
                  </Actions>
                </TextContent>
              </TextContainer>
            </Testimonial>
          </Testimonials>
        </TestimonialsContainer>
        <ReviewList trano_id={id} />
      </Content>


      <StyledModal
        closeTimeoutMS={300}
        className="mainHeroModal"
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        shouldCloseOnOverlayClick={true}
      >
        <CloseModalButton onClick={toggleModal}>
          <CloseIcon tw="w-6 h-6" />
        </CloseModalButton>
        <div className={componentId !== 1 ? "content" : "contentcalendar"}>
          {
            componentId === 1 ?
              <CardBookingCalendar
                bookings={booked}
              />
              :
              <Map
                long={longitude}
                lat={latitude}
                name={subheading}
                price={heading}
              />
          }
        </div>
      </StyledModal>
    </Container>
  );
};



