import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "./components/Hero.js";
import FeatureWithSteps from "./components/FeatureWithSteps.js";
import macHeroScreenshotImageSrc from "images/capture.png";
import upslash from "images/upslash.jpeg";
import Features from "./components/ThreeColWithSideImage.js";
// import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { HOME_TITRE, HOME_TRANO_VACANCE } from "helpers/soratra.js";

import Testimonial from "./components/ThreeColumnWithProfileImage.js";

export default () => {
    const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
    const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;

    const imageCss = tw`rounded-4xl`;

    return (
        <AnimationRevealPage>
            {/* <Header /> */}
            <Hero
                heading={<> {HOME_TITRE} <HighlightedText>{HOME_TRANO_VACANCE}</HighlightedText></>}

                imageSrc={upslash}
                imageCss={imageCss}
                imageDecoratorBlob={true}
                primaryButtonText="Mahajanga"
                watchVideoButtonText="Chercher"
            />
            {/* <Testimonial /> */}
            <Features
                // subheading={<Subheading>Features</Subheading>}
                heading={
                    <>
                        notre service
                    </>
                }
            />
            <FeatureWithSteps
                subheading={<Subheading>Fomba fiasa</Subheading>}
                heading={
                    <>
                        Prise en <HighlightedText>main </HighlightedText>
                    </>
                }
                textOnLeft={false}
                imageSrc={macHeroScreenshotImageSrc}
                imageDecoratorBlob={true}
                decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
            />
            <Testimonial
                subheading=""
                heading={<> notre équipes</>}
            />
            <Footer />
        </AnimationRevealPage>
    );
}
