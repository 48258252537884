// YourMainComponent.js
import React, { useEffect, useState } from 'react';
import ReviewModal from './ReviewModal'; // Adjust the path as needed
import { GET_TRANO_API_REVIEW, POST_TRANO_API_REVIEW } from 'helpers/api';
import tw from 'twin.macro';
import ReactRating from "react-rating";

import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";

const ReviewContainer = tw.div`mt-8 bg-gray-100 p-4 rounded-lg`;
const ReviewItem = tw.div`border-b last:border-b-0 py-2 flex items-center`;
const ReviewerName = tw.p`font-bold text-sm`;
const ReviewText = tw.p`text-xs text-gray-600 mt-1`;
const RatingContainer = tw.div`flex items-center p-4`;
const AddReviewButton = tw.button`bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800 text-black px-4 py-2 rounded mt-4`;

const ReviewList = ({ trano_id }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    const fetchReviews = async (id) => {
        try {
            const data = await GET_TRANO_API_REVIEW(id);
            setReviews(data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleReviewSubmit = async (reviewData) => {
        try {
            reviewData.trano = trano_id; // Add the trano_id to the review data
            await POST_TRANO_API_REVIEW(reviewData);
            alert('Review submitted successfully');
            fetchReviews(trano_id); // Refresh the reviews after submission
        } catch (error) {
            alert('Error submitting review');
        }
    };

    useEffect(() => {
        fetchReviews(trano_id);
    }, [trano_id]);

    if (loading) return <p>Loading reviews...</p>;
    if (error) return <p>Error fetching reviews: {error.message}</p>;

    return (
        <ReviewContainer>
            <AddReviewButton onClick={toggleModal}>Review</AddReviewButton>
            <ReviewModal
                isOpen={isModalOpen}
                onRequestClose={toggleModal}
                onSubmit={handleReviewSubmit}
            />
            <div tw="mt-8 bg-gray-100 p-4 rounded-lg">
                {reviews.length > 0 ? (
                    reviews.map((review, index) => (
                        <ReviewItem key={index}>
                            <RatingContainer>
                                <ReactRating
                                    readonly
                                    placeholderRating={Number(review.rating)}
                                    emptySymbol={<StarIcon style={{ color: "beige" }} />}
                                    placeholderSymbol={<StarIcon style={{ color: "beige", fill: "beige" }} />}
                                />
                            </RatingContainer>

                            <div tw="ml-4">
                                <ReviewerName> {review.reviewer_username}</ReviewerName>
                                <ReviewText>{review.date}</ReviewText>
                                {review.comment}
                            </div>
                        </ReviewItem>
                    ))
                ) : (
                    <p>No reviews yet.</p>
                )}
            </div>
        </ReviewContainer>
    );
};

export default ReviewList;
