import React from "react";
import GlobalStyles from 'styles/GlobalStyles';

// import ComponentRenderer from "ComponentRenderer.js";
import MainLandingPage from "MainLandingPage.js";
import ThankYouPage from "ThankYouPage.js";

import ContextProvider from './Context/ContextProvider';

/* Inner Pages */
import Home from "pages/Home/index.js"
import Houses from "pages/Houses"
import Resend from "pages/Resend";
import SignupPage from "pages/Signup";
// admin
import Reservation from 'pages/Reservation'

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Details from "pages/Details";
import Conditions from "pages/Conditions";
import Profile from "pages/Profile";
import Activate from "pages/Activate";
import LoginPage from "pages/Login";
// import Dashboard from "pages/Dashboard";
import Modal from "pages/Profile/components/stripe/index";
import ThankYouStripe from "pages/Profile/components/stripe/ThankYou";
import Host from "pages/Host";
import Pay from "pages/Profile/components/stripe/Pay";
import Payment from "pages/Profile/components/stripe/Payment";
import HousesCategory from "pages/HousesCategory";

export default function App() {

  return (
    <>
      <GlobalStyles />
      <Router>
        <ContextProvider>
          <Routes>
            {/* <Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} /> */}
            {/* <Route path="/components/:type/:name" element={<ComponentRenderer />} /> */}

            <Route path="/testa" element={<MainLandingPage />} />
            <Route path="/" element={<Home />} />
            <Route path="/trano/destination/:destination" element={<Houses />} />
            <Route path="/trano/category/:category" element={<HousesCategory />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<SignupPage />} />
            <Route path='/activate/:link' element={<Activate />} />
            <Route path='/send-mail' element={<Resend />} />
            <Route path='/trano/details/:id' element={<Details />} />
            <Route path='/reservation' element={<Reservation />} />
            <Route path='/conditions' element={<Conditions />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/add/trano' element={<Host />} />
            {/* <Route path='/dashboard/:id' element={<Dashboard />} /> 
             <Route path="/login" component={Login} />
            <Route path="/create-account" component={CreateAccount} />
            <Route path="/forgot-password" component={ForgotPassword} /> */}
            <Route path='/stripe/deposit' element={<Modal />} />
            <Route path="/stripe/deposit/thank-you" element={<ThankYouStripe />} />
            <Route path="/stripe/pay" element={<Pay />} />
            <Route path="/stripe/payment" element={<Payment />} />
          </Routes>
        </ContextProvider>
      </Router>
    </>
  );
}
