import { useContext, useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import styled from 'styled-components';
import tw from 'twin.macro';

import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_DEPOSIT } from "helpers/api";
import { ProductContext } from "Context/ContextProvider";

const Body = styled.div`
  ${tw`p-4`}
`;
const Backdrop = styled.div`
  ${tw`fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-50 z-10`}
`;
const Container = styled.div`
  ${tw`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg z-20`}
  width: 90%;
  max-width: 600px;
`;


function Payment() {
    const { state } = useContext(ProductContext);
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");

    const setClient = async () => {
        let clientSecre = await STRIPE_DEPOSIT({ amount: state.amount_to_pay });
        setClientSecret(clientSecre['clientSecret']);
    };

    useEffect(() => {
        setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY));
    }, []);

    useEffect(() => {
        setClient()
    }, []);
    debugger
    return (
        <>
            <Backdrop >
                <Container>
                    <Body>
                        <h1>Paiement reservation</h1><br />
                        {clientSecret && stripePromise && (
                            <Elements stripe={stripePromise} options={{ clientSecret }}>
                                <CheckoutForm />
                            </Elements>
                        )}
                    </Body>
                </Container>
            </Backdrop>
        </>
    );
}

export default Payment;