import React, { useContext } from "react";
// import { AiOutlineMinus } from "react-icons/ai";
// import { RiDeleteBinLine } from "react-icons/ri";
import { ProductContext, ProductDispath } from "Context/ContextProvider";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";


export default function Buttons(props) {
    const { dispath } = useContext(ProductDispath);
    const { state } = useContext(ProductContext);
    const { id } = props;
    const countItem = state.basket.find((product) => product.id === id);
    return (
        <div className="basket_buttons">
            <span className="">{props.count}</span><span>jour (s)</span>
            <span>{props.start_date} - {props.end_date}</span>
            {/* {countItem.count === 1 ? (
                <span
                    onClick={() => dispath({ type: "REMOVE_FROM_BASKET", payload: id })}
                    className="basket_minus"
                >
                    Enlever
                </span>
            ) : (
                <span
                    onClick={() => dispath({ type: "DECREASE", payload: id })}
                    className="basket_minus"
                >
                    <MinusIcon />
                </span>
            )} */}
        </div>
    );
}
