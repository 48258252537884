import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { SectionHeading as HeadingTitle } from "components/misc/Headings.js";

import tw from "twin.macro";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import styled from "styled-components";
import "./styles.css";
import villa from "images/villa.jpeg"
import bungalow from "images/bungalow.jpeg"

// import required modules
import { Navigation, Pagination, Autoplay } from "swiper";


const Container = tw.div`relative bg-gray-100`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;
const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm lg:max-w-xs`;
const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`bg-cover bg-center h-80 lg:h-64 rounded`
]);
const Category = tw.div`mt-4 text-secondary-100 font-bold text-sm`;
const Title = tw.h4`mt-2 leading-relaxed font-bold text-xs`;
const Link = tw.a`inline-block mt-2 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;


function Carouselina({ heading = "Amazing Features", description = "Amazing Features", category = [] }) {
    const [swiperRef, setSwiperRef] = useState(null);
    const [slidesPerView, setSlidesPerView] = useState(3);
    let destinations = [
        {
            imageSrc: "https://gateway.pinata.cloud/ipfs/QmeNBDu2UW2qnexsna5qvRygqkLBRq2sXPiKY4GY5pPmHS",
            category: "Villa",
            title: "grande maison de vacances qui peut accueillir un groupe de personnes ou une famille. ",
            url: "/trano/category/Villa",
            count_: category['VILLA']
        },
        {
            imageSrc: "https://gateway.pinata.cloud/ipfs/QmcKSRGw4TJNwJ7vS3bkCgPzrtoTKqnAQCNxTdk3Q4CbgG",
            category: "Studio",
            title: "petit appartement constitué d'une pièce unique salon, de chambre et de cuisine.",
            url: "/trano/category/Studio",
            count_: category['STUDIO']
        },
        {
            imageSrc: "https://gateway.pinata.cloud/ipfs/QmSNACGEhuz4wv3Ao6gDLFsomscZxJact4SuXjouoByQfK",
            category: "Hotel",
            title: "établissement offrant des chambres à louer pour une courte durée.",
            url: "/trano/category/Hotel",
            count_: category['HOTEL']
        },
        {
            imageSrc: "https://gateway.pinata.cloud/ipfs/Qmegv2Jk5H8Bbu9NWjk3QVWrDfRHD38kFtyvqXV2AQ9t2h",
            category: "Bungalow",
            title: "petite maison individuelle souvent construite en bois ou en matériaux naturels. ",
            url: "/trano/category/Bungalow",
            count_: category['BUNGALOW']
        },
        {
            imageSrc: "https://gateway.pinata.cloud/ipfs/QmUkVWqFkyGQDddJiwgGpEiLWFc46qQQZsAJThMQ9nQCd1",
            category: "Appartement",
            title: "logement individuel situé dans un bâtiment comprenant plusieurs unités de logement.",
            url: "/trano/category/Appartement",
            count_: category['APPARTEMENT']
        },
    ];

    useEffect(() => {
        debugger
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setSlidesPerView(1);
            } else {
                setSlidesPerView(3);
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Container>
            <Content>
                <HeadingInfoContainer>
                    <HeadingTitle>{heading}</HeadingTitle>
                    <HeadingDescription>{description}</HeadingDescription>
                </HeadingInfoContainer>

                <Swiper
                    onSwiper={setSwiperRef}
                    effect={"coverflow"}
                    centeredSlides={true}
                    slidesPerView={slidesPerView}
                    spaceBetween={30}
                    pagination={{
                        type: "fraction",
                    }}
                    className="mySwiper"
                    loop={true}
                    navigation={true}
                    modules={[Pagination, Navigation, Autoplay]}

                    autoplay={{ delay: 5000 }}
                >
                    {destinations.map((item, index) => (
                        // <Column >
                        <SwiperSlide key={index} >
                            <Card>
                                <Image imageSrc={item.imageSrc} />
                                <Category>{item.category}</Category>
                                <Title>{item.title}</Title>
                                {

                                    // item.count_ &&
                                    <Link href={item.url}>{item.count_} {item.category} enregistré(es)</Link>
                                }

                            </Card>
                        </SwiperSlide>
                        // </Column>
                    ))}

                </Swiper>
            </Content>

        </Container>

    );
}


export default Carouselina;
