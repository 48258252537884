import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { motion } from "framer-motion";
import { COMPLETE_BOOK, COMPTE, GET_MY_BOOKING } from "helpers/api";
import { useNavigate } from "react-router-dom";
import { ProductContext, ProductDispath } from "Context/ContextProvider";
import { DETAIL_NO_RESERVATION } from "helpers/constant";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";


const CardButton = tw(PrimaryButtonBase)`text-sm`;
const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;
const CardReview = tw.div`font-medium text-red-600 text-xs`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;

const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;

export default () => {
  const { dispath } = useContext(ProductDispath);
  const { state } = useContext(ProductContext);
  const [data, setData] = useState()
  const [loaded, setLoaded] = useState()
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const loadData = async () => {
    // pass = pass.value

    try {
      let res = await GET_MY_BOOKING()
      if (res.status === 200) {
        setData(res.data)
        debugger
        // console.log(res.data)
        setLoaded(true)
      }
    } catch (error) {
      if (error.response.status === 401) {
        alert("Session expiree")
        dispath({ type: "OUT_SESION" });
        navigate("/login")
      }
    }
  };

  const handleToReservation = () => {
    navigate("/reservation")
  }

  // const handleSubmit = async (event, book_id) => {
  //   //Prevent page reload
  //   event.preventDefault();
  //   setIsLoading(true);

  //   let playload = {
  //     book_id: book_id
  //   }
  //   try {
  //     let res = await COMPLETE_BOOK(playload)
  //     if (res.status === 200) {
  //       // alert("payment booking succeed")
  //       dispath({ type: "UPDATE_PROFILE", payload: res.data });
  //       toast.info("Vous avez payé la totalité de la réservation. Passez un agréable séjour et rendez-vous bientôt.")
  //     }
  //     setIsLoading(false)
  //     loadData()

  //   } catch (error) {
  //     if (error.response.status === 401) {
  //       // alert("Session expiree")
  //       dispath({ type: "OUT_SESION" });
  //       toast.info("Erreur")
  //     }
  //     setIsLoading(false)

  //   }
  // };
  useEffect(() => {
    !loaded &&
      loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ToastContainer />
      {
        state.basket.length > 0 &&
        state.basket.map((item, index) => (
          <CardContainer key={index}>
            <Card className="group" initial="rest" whileHover="hover" animate="rest">
              <CardImageContainer imageSrc={item.images}>
                {
                  <CardHoverOverlay
                    variants={{
                      hover: {
                        opacity: 1,
                        height: "auto"
                      },
                      rest: {
                        opacity: 0,
                        height: 0
                      }
                    }}
                    transition={{ duration: 0.3 }}
                  >
                    <CardButton onClick={() => handleToReservation()}> Confirmation reservation</CardButton>
                  </CardHoverOverlay>
                }
              </CardImageContainer>
              <CardText>
                <CardTitle>{item.name}</CardTitle>
                <CardContent>
                  <CardReview>A confirmer</CardReview>
                </CardContent>
                <CardPrice>
                  {item.price} Ar/jour</CardPrice>
              </CardText>
            </Card>
          </CardContainer>
        ))
      }
      {
        loaded &&
        data?.map((item, index) =>
          <CardContainer key={index}>
            <Card className="group" initial="rest" whileHover="hover" animate="rest">
              <CardImageContainer imageSrc={item['trano'].images}>
                {/* {
                  !item['isPaid'] &&
                  <CardHoverOverlay
                    variants={{
                      hover: {
                        opacity: 1,
                        height: "auto"
                      },
                      rest: {
                        opacity: 0,
                        height: 0
                      }
                    }}
                    transition={{ duration: 0.3 }}
                  >
                    <CardButton
                      onClick={(event) => handleSubmit(event, item['id'])}
                    > 
                    {isLoading && <FadeLoader height={6} color="#36d7b7" cssOverride={{ height: '1.5rem', top: '0.5rem' }} />}

                    Payer le reste</CardButton>
                  </CardHoverOverlay>
                } */}
              </CardImageContainer>
              <CardText>
                <Link to={`/trano/details/${item['trano'].id}`}>
                  <CardTitle >{item['trano'].name}</CardTitle>
                  {item['trano'].contact && <CardContent>Contact: {item['trano'].contact}</CardContent>}
                  {item['trano'].addresse && <CardContent>Addresse: {item['trano'].addresse}</CardContent>}
                </Link>
                <CardContent>Arrivée: {item['dateIn']}</CardContent>
                <CardContent>Départ: {item['dateOut']}</CardContent>
                <CardContent> {item['isPaid'] ? <CardReview>Montant total Payé</CardReview> : <CardReview>Reservation Payé</CardReview>}</CardContent>
                <CardPrice>Total: {item['total_amount']} Ar</CardPrice>
              </CardText>
            </Card>
          </CardContainer>
        )
      }
      {
        data?.length === 0 &&
        <div className="favorite_empty">
          <span className="favorite_empty_title">{DETAIL_NO_RESERVATION}</span>
        </div>
      }
    </>
  );
};
