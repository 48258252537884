import React, { useContext, useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import macHeroScreenshotImageSrc from "images/capture.png";

import { ProductContext, ProductDispath } from "Context/ContextProvider";
import { LOGIN_API } from "helpers/api";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FadeLoader from "react-spinners/FadeLoader";
import { LOGIN_OUVRIR_COMPTE, SEND_REQUEST } from "helpers/soratra";

const Container = tw(ContainerBase)`min-h-screen bg-white text-white font-medium flex justify-center -mr-8 -ml-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900  sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const MainContent = tw.div`flex flex-col items-center`;
const Heading = tw.h1`text-2xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 `;
const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1  text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

export default ({
    logoLinkUrl = "/trano",
    illustrationImageSrc = macHeroScreenshotImageSrc,
    headingText = "Connexion",

    submitButtonText = "Envoie demande listing",
    forgotPasswordUrl = "/send-mail",
    signupUrl = "/register",

}) => {

    const { state } = useContext(ProductContext);
    const { dispath } = useContext(ProductDispath);
    const [isLogged, setIsLogged] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        //Prevent page reload
        event.preventDefault();
        setIsLoading(true);

        var { uname, pass } = document.forms[0];
        let username = uname.value
        let password = pass.value
        // pass = pass.value
        try {
            let res = await LOGIN_API({ username: username, password: password })
            console.log(res)
            dispath({ type: "LOGIN", payload: res.data });
            setIsLogged(true)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            toast.error(error)
        }
    };

    useEffect(() => {

    }, [])

    return (
        <>
            {
                state.user ?
                    <Navigate to="/profile/" />
                    :
                    <AnimationRevealPage>
                        <Header />
                        <ToastContainer />
                        <Container>
                            <Content>
                            <MainContainer>
                                    <MainContent>
                                        {/* <Heading>{headingText}</Heading> */}
                                        <FormContainer>

                                            <Form onSubmit={handleSubmit}>
                                                <Input required  type="text" id="destination" name="destination"  placeholder="Mahajanga" />
                                                <Input required type="text" id="name" name="name" placeholder="Villa-pazapapa-01" />
                                                <Input required type="textarea" id="description" name="description" placeholder="Villa situe au bord de la mer, avec resto" />
                                                <Input required type="number" id="price" name="price" placeholder="50000" />
                                                <Input required type="number" id="max_pers" name="max_pers" placeholder="5"  />
                                                <Input required type="number" id="nbr_beds" name="nbr_beds" placeholder="2"  />

                                                <label for="category">Category:</label>
                                                <select id="category" name="category">
                                                    <option value="VILLA" >VILLA</option>
                                                    <option value="HOTEL" >HOTEL</option>
                                                </select>
               
                                            </Form>
                                          
                                        </FormContainer>
                                    </MainContent>
                                </MainContainer>
                                <MainContainer>
                                    <MainContent>
                                        {/* <Heading>{headingText}</Heading> */}
                                        <FormContainer>

                                            <Form onSubmit={handleSubmit}>
           
                                                <label for="is_secured">Secured:</label>
                                                <Input required  type="checkbox" id="is_secured" name="is_secured"  />
                                                <label for="tv">Television:</label>
                                                <Input required type="checkbox" id="tv" name="tv" />
                                                <label for="wifi">Wifi:</label>
                                                <Input required type="checkbox" id="wifi" name="wifi" />
                                                <label for="can_cook">Cuisine:</label>
                                                <Input required type="checkbox" id="can_cook" name="can_cook" />
                                                 
                                                <SubmitButton type="submit">

                                                    {isLoading && <FadeLoader height={6} color="#36d7b7" cssOverride={{ height: '1.5rem', top: '0.5rem' }} />}
                                                    <span className="text">{submitButtonText}</span>
                                                </SubmitButton>
                                            </Form>
                                          
                                        </FormContainer>
                                    </MainContent>
                                </MainContainer>
                                <IllustrationContainer>
                                    <IllustrationImage imageSrc={illustrationImageSrc} />
                                </IllustrationContainer>
                               

                            </Content>
                        </Container>
                        <Footer />
                    </AnimationRevealPage>
            }
            {
                isLogged && <Navigate to="/profile/" />
            }
        </>
    )
};
