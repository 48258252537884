import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { ReactComponent as SvgDecoratorBlob1 } from "../../../images/svg-decorator-blob-1.svg";
import { ProductContext, ProductDispath } from "Context/ContextProvider";
import { GET_DESTINATION, GET_VISITOR, GET_CATEGORY } from "helpers/api";
import { HOME_DESCRIPTION_1, HOME_DESCRIPTION_2, HOME_DESCRIPTION_3 } from "helpers/soratra";
import Carouselina from "./Carouselina"
// import pexels from "images/pexels-photo-346768.gif";
import Header from "components/headers/light.js";
// const Container = tw.div`relative bg-cover`;
// const Container = tw.div`relative  ml-[-2rem] mr-[-2rem] mb-[-6rem] bg-cover bg-center bg-no-repeat bg-[url('https://gateway.pinata.cloud/ipfs/QmTwrpUJoKU8HPctaqnpASqRH6uBh4w8v9qQzNqkF3433r')]`;
const Container = tw.div`relative -mt-4 pt-4 ml-[-2rem] mr-[-2rem] lg:bg-repeat-round bg-[url('https://gateway.pinata.cloud/ipfs/QmTwrpUJoKU8HPctaqnpASqRH6uBh4w8v9qQzNqkF3433r')]`;
const Headmobile = tw.div`px-8`;
// const Container = tw.div`relative bg-cover ml-[-2rem] mr-[-2rem] bg-center bg-no-repeat bg-[url('https://img.freepik.com/free-photo/female-tourists-spread-their-arms-held-their-wings_1150-7449.jpg?w=740&t=st=1679472637~exp=1679473237~hmac=5a8abc6df2f56ea65a61a8d8744deb3ab3684d7b5cf42efa58eca38438254ccb')]`;

const TwoColumn = tw.div`flex flex-col md:items-center max-w-screen-xl mx-auto px-4 py-8`;

const Heading = tw.h1`-mt-8 pt-2 font-black text-center text-3xl md:text-5xl leading-snug max-w-3xl`;
const Paragraph = tw.p`p-2 my-5 text-blue-700  text-center lg:my-8 text-sm lg:text-base font-medium max-w-lg mx-auto lg:mx-0`;
const Actions = styled.div`
  ${tw`-mb-16 z-10 rounded-xl max-w-xl bg-purple-800 bg-opacity-25 flex justify-between p-4`}
  padding: 0.5rem;
  margin-inline: 1rem;
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
     ${tw`rounded-xl h-16 sm:mt-0 bg-primary-500 text-gray-100 hover:bg-primary-700`}
  }
  .secondaryAction {
    ${tw`rounded-xl h-16 sm:mt-0 mr-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800`}
  }
`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;


export default ({
  heading = "Modern React Templates, Just For You",
}) => {

  const { state } = useContext(ProductContext);
  const { dispath } = useContext(ProductDispath);
  const [url, setUrl] = useState('trano/destination/Mahajanga')

  const loadData = async () => {
    let res__ = await GET_DESTINATION();
    dispath({ type: "LOAD_DESTINATION", payload: res__.data.data });
    let res___ = await GET_CATEGORY();
    dispath({ type: "LOAD_CATEGORY", payload: res___.data.data });
    res__ = await GET_VISITOR();
    dispath({ type: "LOAD_VISITOR", payload: res__.data.visitor_count });
    console.log(res__.data.visitor_count);

  }

  useEffect(() => {
    !state.load_destination &&
      loadData()
    // console.log(state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])


  return (
    <>

      <Container>
        <Headmobile>
          <Header />
        </Headmobile>

        <TwoColumn>
          {/* <LeftColumn> */}
          <Heading>{heading}</Heading>
          <Paragraph>
            {/* <b> {HOME_DESCRIPTION_1}<br /> */}
            {/* <br /> </b> */}
            {HOME_DESCRIPTION_2}
            <br /> {HOME_DESCRIPTION_3}

          </Paragraph>

          <Actions>
            {/* <input type="text" disabled placeholder={primaryButtonText} />
              <button >
                <a className="playText" href="/trano">{watchVideoButtonText}</a>
              </button> */}
            <select
              onChange={e => setUrl('trano/destination/' + e.target.value)}
              className="action secondaryAction">
              <option className="action secondaryAction" value={"Mahajanga"}>Mahajanga</option>
              {
                state.destination.map((item, index) =>
                  <option className="action secondaryAction" key={index} value={item}>{item}</option>
                )
              }
            </select>
            {/* <a href={"#"} className="action secondaryAction">
                go
                </a> */}
            <a href={url} className="action primaryAction">
              {"Continuer"}
            </a>

          </Actions>

          {/* </LeftColumn> */}
          {/* <RightColumn>
            <IllustrationContainer>
              <img
                css={imageCss}
                src={imageSrc}
                alt="Hero"
              />
              {imageDecoratorBlob && <DecoratorBlob2 />}
            </IllustrationContainer>
          </RightColumn> */}
        </TwoColumn>
        <DecoratorBlob1 />
        <Carouselina
          heading="Type hebergement"
          description="Cliquez sur le type d'hebergement de votre preference"
          category={state.category}
        />
      </Container >

    </>
  );
};
