export const HEADER_RESERVATION = "Reservation"
export const HEADER_COMPTE = "Votre compte"
export const FOOTER_A_PROPOS = "A propos de nous"
export const HOME_TITRE = "Reservation en ligne"
export const HOME_TRANO_VACANCE = "Trano vacance"
export const HOME_DESCRIPTION_1 = "Milamina tsara jerinao hiatrika ny vacance"
export const HOME_DESCRIPTION_2 = "Facile & Rapide"
export const HOME_DESCRIPTION_3 = "Ne souciez plus de votre hébergement pour vacance ou mission à Madagascar avec trano-vacance.mg"

export const FAQ_TITRE = "Conditions de location"
export const FAQ_TITRE_DESC = "Nous vous invitons à lire chaque ligne, afin d'éviter des problèmes"
export const LOGIN_OUVRIR_COMPTE = "Cliquer ici pour ouvrir un compte"

export const FEATURES_DESC_1 = "Page accueil, choisissez destination. Puis glisser sur slider de budget et date pour filtrer."
export const FEATURES_DESC_2 = "Faite votre choix selon les options disponibles et vérifiez si c'est adaptée à vos besoins. puis cliquez sur réserver."
export const FEATURES_KEY_2 = "Voir détail puis réserver"
export const FEATURES_KEY_4 = "Confirmer votre réservation"
export const FEATURES_DESC_3 = "Se connecter ou creer compte sur trano-vacance mg, puis après vous pouvez choisir mode de paiement pour alimenter votre compte."
export const FEATURES_DESC_4 = "Il ne reste plus qu'à confirmer votre réservation, et voilà c'est tout! Vous recevrez message de notification et facture par email."

export const SEND_REQUEST = "Envoie demande ajout"
