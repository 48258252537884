// ReviewModal.js
import React, { useState } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import tw from 'twin.macro';
import { PrimaryButton } from 'components/misc/Buttons.js';
import ReactRating from "react-rating";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";

const ModalContent = styled.div`
  ${tw`p-8 bg-white rounded-lg shadow-lg`}
  width: 400px;
  max-width: 100%;
  position: relative;
`;

const CloseButton = styled.button`
  ${tw`absolute top-0 right-0 mt-4 mr-4 text-gray-600`}
`;

const FormField = styled.div`
  ${tw`my-4`}
`;

const FormLabel = styled.label`
  ${tw`block text-sm font-semibold text-gray-700`}
`;

const FormInput = styled.input`
  ${tw`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-primary-500`}
`;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        padding: '0',
        background: 'transparent',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 1000,
    },
};

const ReviewModal = ({ isOpen, onRequestClose, onSubmit }) => {
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ rating, comment });
        onRequestClose(); // Close the modal on submit
    };

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
            contentLabel="Add Review"
        >
            <ModalContent>
                <CloseButton onClick={onRequestClose}>×</CloseButton>
                <h2 className="text-xl font-semibold mb-4">Ajout de review</h2>
                <form onSubmit={handleSubmit}>
                    <FormField>
                        <FormLabel></FormLabel>
                        <ReactRating
                            initialRating={rating}
                            onChange={setRating}
                            emptySymbol={<StarIcon style={{ color: "beige" }} />}
                            placeholderSymbol={<StarIcon style={{ color: "beige", fill: "beige" }} />}
                            fullSymbol={<StarIcon style={{ color: "beige", fill: "beige" }} />}
                        />
                    </FormField>
                    <FormField>
                        <FormLabel htmlFor="comment">Commentaire</FormLabel>
                        <FormInput
                            as="textarea"
                            id="comment"
                            rows="4"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            required
                        />
                    </FormField>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </form>
            </ModalContent>
        </ReactModal>
    );
};

export default ReviewModal;