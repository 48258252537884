export const SITE_NAME = "vakansy"
// Home 
export const TITLE = "Mbola tsara e!"
export const TITLE_ = "Location et reservation de maison en 3 cliques"
export const TITLE_LINK = "Chercher"
// Services
export const SERVICES = "Services"
export const SERVICE_TITLE_1 = "Rapide"
export const SERVICE_TITLE_1_1 = "Les options disponibles seront offertes selon votre filtre de recherche."
export const SERVICE_TITLE_2 = "Fiable"
export const SERVICE_TITLE_2_1 = "Fini les arnaques sur internet. Les infos que vous trouvez ici sont adéquates."
export const SERVICE_TITLE_3 = "Accessible"
export const SERVICE_TITLE_3_1 = "Les payements de réservations se font par mobile money. Accessible à tous moment."
export const SERVICE_TITLE_4 = "Professionelle"
export const SERVICE_TITLE_4_1 = "Notre équipe sera à votre écoute pour répondre à vos besoins."
// Footer
export const COPYRIGHT = "©Copyright @ELITE401 - 2022"
export const ABOUT = "A propos de nous"
export const ABOUT_ADDRESS = "Lot IIL61 bis Ankadivato - Tana 101"
export const ABOUT_NIF = "xxx-XXXX-XXXXX"
export const ABOUT_STAT = "xxx-XXXX-XXXXX"
export const ABOUT_CONTACT_1 = "0342177510"
export const ABOUT_CONTACT_2 = "0326731581"
export const ABOUT_MAIL = "maisons_vakansy@gmail.com"
export const ABOUT_HOTCALL = "Support"
// Search bar
export const FILTER = "Votre filtre"
export const FILTER_MIN_PRICE = "Budget min."
export const FILTER_MAX_PRICE = "Budget max."
export const FILTER_LIT = "Nombre lit min."
export const FILTER_DATE_IN = "Check in date"
export const FILTER_DATE_OUT = "Check out date"
// Details
export const DETAIL_PRECEDENT = "Precedent"
export const DETAIL_RESERVER = "Reserver"
export const DETAIL_CONFIRMATION = "Confirm reservation"
export const DETAIL_DECLINE = "Annuler selection"
export const DETAIL_NO_RESERVATION = "Vous n'avez pas de reservation"
export const UNITE = "Ar"
export const SOUS_TOTAL = "Sous total"
export const SOMME_TOTAL = "Somme total + frais"
export const DETAIL_NO_TRANO = "Vous n'avez pas encore de maison a louer dans le site"
