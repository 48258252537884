import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { HiArrowLeft } from "react-icons/hi";
import { ProductContext, ProductDispath } from "Context/ContextProvider";
import Item from "./components/Items";
import { BOOK, GET_BOOKED_TRANO_API } from "helpers/api";
import { COMPTE } from "helpers/api";
import { css } from "styled-components/macro"; //eslint-disable-line
import FAQ from "./components/SingleCol.js";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/headers/light.js";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as SvgDecoratorBlob } from "images/svg-decorator-blob-6.svg";
import { ContentWithPaddingXl } from "components/misc/Layouts.js";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FadeLoader from "react-spinners/FadeLoader";
import { FAQ_TITRE, FAQ_TITRE_DESC } from "helpers/soratra";

const Row = tw.div`flex flex-col md:flex-row justify-between items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 xl:w-6/12 flex-shrink-0 relative`;
const PlanAction = tw.div`px-4 py-4`;
const PlansContainer = tw.div`flex justify-between flex-col lg:flex-row items-center lg:items-stretch relative`;
const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Heading = tw(SectionHeading)`w-full text-xl`;
const Plan = styled.div`
  ${tw`w-full max-w-sm mt-4 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }
  ${props =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
        background: rgb(85,60,154);
        background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
        background: rgb(76,81,191);
        background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
    `}
`;
const TextColumn = styled(Column)(() => [
  tw`flex justify-around `,
  // props.textOnLeft ? tw`md:pr-12 lg:pr-16 md:order-first` : tw`md:pl-12 lg:pl-16 md:order-last`
]);

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;
const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-1/2 translate-y-1/2`}
`;


export default function Basket({ plans = null }) {
  const { state } = useContext(ProductContext);
  const { dispath } = useContext(ProductDispath);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const updateData = async () => {
    // dispath({ type: "EMPTY_BASKET" })
    let res = state.allProducts;
    // update info profile
    let res_ = await GET_BOOKED_TRANO_API();
    dispath({ type: "LOAD_BOOKED_TRANO", payload: { "booked": res_.data, "trano": res } });
    navigate("/profile")
  };
  const formatPrice = (price) => {
    return price.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }
  const confirm = async () => {
    setIsLoading(true);
    if (state.token === "") {
      navigate("/login")
    }
    else {
      debugger
      if (state.basket[0].owner_id === state.user.id) {
        let playload = {
          total_amount: 0,
          dateOut: state.basket[0].end_date,
          dateIn: state.basket[0].start_date,
          trano_id: state.basket[0].id
        }
        try {
          let res = await BOOK(playload);
          // console.log(res)
          if (res.status === 200) {
            setIsLoading(false)
            let res = await COMPTE()
            if (res.status === 200) {
              dispath({ type: "COMPTE", payload: res.data });
              dispath({ type: "EMPTY_BASKET" })
            }
            toast.info("Reservation reussi!")
            await updateData()
          }
        } catch (error) {
          if (error.response.status === 401) {
            toast.error("Session expiree!")
            dispath({ type: "OUT_SESION" });
            navigate("/login")
          }
          if (error.response.status === 400) {
            setIsLoading(false)
            toast.error(error.response.data.message)
          }
        }

      }
      else {
        if (parseInt(state.totalPrice) > parseInt(state.user.mobile_money)) {
          setIsLoading(false)
          toast.error("Votre solde n'est pas suffisant, Faite depot superieur a " + (state.totalPrice).toString() + " ar")
        }
        else {
          if (parseInt(state.totalPrice) > 0) {
            let playload = {
              total_amount: state.totalPrice,
              dateOut: state.basket[0].end_date,
              dateIn: state.basket[0].start_date,
              trano_id: state.basket[0].id
            }
            try {
              let res = await BOOK(playload);
              // console.log(res)
              if (res.status === 200) {
                setIsLoading(false)
                let res = await COMPTE()
                if (res.status === 200) {
                  dispath({ type: "COMPTE", payload: res.data });
                  dispath({ type: "EMPTY_BASKET" })
                }
                toast.info("Reservation reussi!")
                await updateData()
              }
            } catch (error) {
              if (error.response.status === 401) {
                toast.error("Session expiree!")
                dispath({ type: "OUT_SESION" });
                navigate("/login")
              }
              if (error.response.status === 400) {
                setIsLoading(false)
                toast.error(error.response.data.message)
              }
            }
          }
          else {
            setIsLoading(false)
            toast.error("Vous avez mal choisi la date!")
          }
        }
      }
    }
  }
  const defaultPlans = [
    {
      name: "Personal",
      price: "$17.99",
      duration: "Monthly",
      mainFeature: "Suited for Personal Blogs",
      features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"],
    }

  ];

  if (!plans) plans = defaultPlans;
  const highlightGradientsCss = [
    css`
          background: rgb(56, 178, 172);
          background: linear-gradient(115deg, rgba(56, 178, 172, 1) 0%, rgba(129, 230, 217, 1) 100%);
        `,
    css`
          background: rgb(56, 178, 172);
          background-image: linear-gradient(115deg, #6415ff, #7431ff, #8244ff, #8e56ff, #9a66ff);
        `,
    css`
          background: rgb(245, 101, 101);
          background: linear-gradient(115deg, rgba(245, 101, 101, 1) 0%, rgba(254, 178, 178, 1) 100%);
        `
  ];

  return (
    <AnimationRevealPage>
      <ToastContainer />
      <Header />
      <ContentWithPaddingXl>
        <HeaderContainer>
          <Heading> Confirmation de reservation</Heading>
        </HeaderContainer>
        <Row>

          <TextColumn textOnLeft={false}>
            <PlansContainer>
              <Plan key={1} >
                <div className="planHighlight" />
                {
                  state.basket.length > 0 ?
                    <>
                      <PlanFeatures>
                        <span className="feature mainFeature">vos selections</span>
                        {state.basket.map((product) => (
                          <Item key={product.id} {...product} />
                        ))}
                      </PlanFeatures>
                      <PlanAction>
                        <BuyNowButton onClick={() => confirm()}>
                          {isLoading && <FadeLoader height={6} color="#36d7b7" cssOverride={{ height: '1.5rem', top: '0.5rem' }} />}
                          Confirmer</BuyNowButton>
                      </PlanAction>
                      <PlanAction>
                        <BuyNowButton onClick={() => dispath({ type: "EMPTY_BASKET" })} css={highlightGradientsCss[2]}>
                          {isLoading && <FadeLoader height={6} color="#36d7b7" cssOverride={{ height: '1.5rem', top: '0.5rem' }} />}
                          Annuler</BuyNowButton>
                      </PlanAction>
                    </>
                    :
                    <div>
                      <div className="feature">
                        Vous n'avez pas de selection en cours
                      </div>
                      <PlanHeader>
                      </PlanHeader>
                      <Link href="/trano">== Tsy nahitana ==</Link>
                      <PlanHeader>
                      </PlanHeader>
                    </div>
                }
                <PlanHeader>
                  <span className="name">Total</span>
                  <span className="price"> {formatPrice(parseInt(state.totalPrice))} Ar</span>
                </PlanHeader>
              </Plan>
              <DecoratorBlob />
            </PlansContainer>
          </TextColumn>
          <ImageColumn>
            <FAQ
              description={FAQ_TITRE_DESC}
              heading={FAQ_TITRE}
              faqs={[
                {
                  question: "Paiement des réservations",
                  answer:
                    "50% du montant total de la réservation sera versé lors de la réservation"
                },
                // {
                //   question: "Penalité",
                //   answer:
                //     "Afin qu'il y ait respect mutuel entre locataires et propriétaires, il y a une sanction en cas d'abus."
                // },
                {
                  question: "Responsabilités du locataire",
                  answer:
                    "Suivez les règles établies par les hôtes s'il y en a telles que la propreté..."
                },
                {
                  question: "Notre responsabilité",
                  answer:
                    // eslint-disable-next-line no-multi-str
                    "Vérifier toutes les informations sur la maison à louer.\
                    Vérifier les abus du propriétaire ou du locataire. \
                    Nous prenons en charge tous les aspects techniques pour faciliter et accélérer votre réservation."
                },
                {
                  question: "Responsabilités du propriétaire",
                  answer:
                    "Propriétaire est obligé de garder les chambres réservées par le client disponibles et de fournir les services convenus."

                },
                {
                  question: "Modification des dates",
                  answer:
                    "Si la maison est encore libre au moment de la nouvelle date de changement, des modifications peuvent être apportées."
                },
                {
                  question: "Réduire les dates",
                  answer:
                    "Il est également possible de réduire la date, mais si le montant est superieur à l'avance, l'argent ne sera pas remboursé."
                },
                {
                  question: "Annulation des dates",
                  answer:
                    "Il n'est pas possible de restituer l'argent qui a déjà été dépensé. Vérifiez donc bien si la date choisie ou le lieu où vous rendre, nous ne sommes pas responsables."
                },
                // {
                //   question: "Sanctions pour les propriétaires",
                //   answer:
                //     "S'il est confirmé qu'il y a abus de la part du propriétaire, le locataire peut partir et le propriétaire remboursera son loyer pour aller voir ailleurs."
                // },
                // {
                //   question: "Sanction aux locataires",
                //   answer:
                //     "Si le locataire est reconnu coupable d'abus, il sera expulsé de la propriété qu'il a réservée. Il n'y a aucun remboursement."
                // },

              ]}
            />
          </ImageColumn>
        </Row>
      </ContentWithPaddingXl>
      <Footer />
    </AnimationRevealPage>
  );
}
