import React, { useContext } from "react";
import styled from "styled-components"; //eslint-disable-line
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ProductContext, ProductDispath } from "Context/ContextProvider";
import pascal from 'images/pascal.jpg'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion } from "framer-motion";

import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";



const CardContainer = tw.div`mt-10 w-full w-1/2 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
  position: relative;
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
  
  @media (max-width: 640px) {
    opacity: 1 !important;
    height: auto !important;
  }
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;


const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;



export default () => {

    const { state } = useContext(ProductContext);
    const { dispath } = useContext(ProductDispath);
    const handleLogout = () => {
        dispath({ type: "LOGOUT" });
        toast.info("logout success!")
    };


    return (
        <>
            <CardContainer >
                <Card className="group" initial="rest" whileHover="hover" animate="rest">
                    <CardImageContainer imageSrc={pascal}>

                        <CardHoverOverlay
                            variants={{
                                hover: {
                                    opacity: 1,
                                    height: "auto"
                                },
                                rest: {
                                    opacity: 0,
                                    height: 0
                                }
                            }}
                            transition={{ duration: 0.3 }}
                        >
                            <CardButton
                                onClick={handleLogout}>Confirmer Logout
                            </CardButton>
                        </CardHoverOverlay>
                    </CardImageContainer>
                    <CardText>
                        <CardTitle>Merci pour votre visite</CardTitle>
                        {/*  <CardPrice>{card.price}</CardPrice> */}
                    </CardText>
                </Card>
            </CardContainer >
            <CardContainer >
                <Card className="group" initial="rest" whileHover="hover" animate="rest">
                    <CardImageContainer imageSrc={pascal}>
                        <CardHoverOverlay
                            variants={{
                                hover: {
                                    opacity: 1,
                                    height: "auto"
                                },
                                rest: {
                                    opacity: 0,
                                    height: 0
                                }
                            }}
                            transition={{ duration: 0.3 }}
                        >
                            <CardButton>
                                <a href="https://web.facebook.com/profile.php?id=100086740967169">
                                    Like page & partage
                                </a>
                            </CardButton>
                        </CardHoverOverlay>
                    </CardImageContainer>
                    <CardText>
                        <CardTitle>Vous avez aimé notre service ?</CardTitle>
                        {/*  <CardPrice>{card.price}</CardPrice> */}
                    </CardText>
                </Card>
            </CardContainer>
        </>
    );
};
