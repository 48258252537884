import React, { useContext, useState, Suspense, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/headers/light.js";
import MainFeature2 from "./components/MainFeature2.js";
import tw from "twin.macro";
import { GET_BOOKED_TRANO_API, GET_TRANO_API } from "helpers/api";
import { useParams } from "react-router-dom";
import { ProductContext, ProductDispath } from "../../Context/ContextProvider";
import SliderCard from "./components/SliderCard.js";

export default () => {

    const imageCss = tw`rounded-4xl`;
    const { state } = useContext(ProductContext);
    const { dispath } = useContext(ProductDispath);
    const params = useParams();

    // eslint-disable-next-line eqeqeq
    const datas = state.allProducts.find((product) => product.id == params.id);

    const [booked, setBooked] = useState([]);
    const [dataOwner, setDataOwner] = useState([]);

    const loadData = () => {
        let list_date = datas?.booked?.split(',');
        setBooked(list_date?.map(item => new Date(item)))
        setDataOwner(
            state.allProducts.filter(
                (product) => product.owner_id === datas.owner_id && product.id !== datas.id
            )
        );
    }
    const loadAllData = async () => {
        let res = await GET_TRANO_API();
        dispath({ type: "LOAD_TRANO", payload: res.data });
        let res_ = await GET_BOOKED_TRANO_API();
        dispath({ type: "LOAD_BOOKED_TRANO", payload: { "booked": res_.data, "trano": res.data } });
    }

    useEffect(() => {
        !state.dataLoaded && loadAllData()
        datas && loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    return (
        <AnimationRevealPage>
            <Header />
            <Suspense fallback={<p>loading...</p>}>
                {
                    state?.dataLoaded &&
                    <MainFeature2
                        subheading={datas.name}
                        category={datas.category}
                        heading={datas.price}
                        destination={datas.destination}
                        extra={datas.extra}
                        description={datas.description}
                        longitude={datas.longitude}
                        latitude={datas.latitude}
                        statistics={[
                            {
                                id: 1,
                                key: "Lit(s)",
                                value: datas.nbr_beds,
                            },
                            {
                                id: 2,
                                key: "Personne(s) maximum",
                                value: datas.max_pers,
                            },
                            {
                                id: 3,
                                key: "Site securisée",
                                value: datas.is_secured
                            },
                            {
                                id: 4,
                                key: "Equipement de cuisine",
                                value: datas.can_cook
                            },
                            {
                                id: 5,
                                key: "Wifi & Internet Gratuit",
                                value: datas.wifi
                            },
                            {
                                id: 6,
                                key: "Climatisée",
                                value: datas.air_con
                            },
                            {
                                id: 7,
                                key: "Petit déjeuner gratuit",
                                value: datas.free_breakfast
                            },
                            {
                                id: 8,
                                key: "Restaurant",
                                value: datas.restaurant
                            },
                            {
                                id: 9,
                                key: "Piscine",
                                value: datas.piscine
                            },
                            {
                                id: 10,
                                key: "SPA",
                                value: datas.spa
                            },
                            {
                                id: 11,
                                key: "Parking Gratuit",
                                value: datas.parking
                            },
                            {
                                id: 12,
                                key: "Eau chaude",
                                value: datas.eau_chaude
                            }
                        ]}
                        primaryButtonText="Reserver"
                        primaryButtonUrl=""
                        imageInsideDiv={false}
                        imageSrc={datas.images}
                        imageSrc_outdoor={datas.image_outdoor}
                        imageSrc_indoor={datas.image_indoor}
                        rating={datas.rating}
                        booked={booked}
                        imageCss={Object.assign(tw`bg-cover`, imageCss)}
                        imageContainerCss={tw`md:w-1/2 h-auto`}
                        imageDecoratorBlob={true}
                        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
                        textOnLeft={true}
                        id={datas.id}
                    />
                }
            </Suspense>
            <Suspense fallback={<p>loading...</p>}>
                {
                    dataOwner?.length > 0 &&
                    <SliderCard
                        heading={"Même proprio"}
                        dataOwner={dataOwner}
                    />
                }

            </Suspense>
            <Footer />
        </AnimationRevealPage>
    );
}