import React, { createContext, useReducer } from "react";
import offerCode from "../Offer";
import { sendPrice } from "../Offer";
import allProducts from "./Data";

let user = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser")).profile
  : "";
let token = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser")).token
  : "";
let basket = localStorage.getItem("basket")
  ? JSON.parse(localStorage.getItem("basket"))
  : [];
let totalPrice = localStorage.getItem("totalPrice")
  ? JSON.parse(localStorage.getItem("totalPrice"))
  : 0;
const initialState = {
  allProducts,
  filteredItems: [...allProducts],
  searchKey: "",
  dataLoaded: false,
  basket,
  totalPrice,
  beds: 0,
  min_budget: 0,
  max_budget: 0,
  day_count: 0,
  start_date: new Date().toISOString().slice(0, 10),
  end_date: new Date().toISOString().slice(0, 10),
  max_pers: 2, // added filter
  favorites: [],
  totalPriceAfterOffer: 0,
  offerPrice: 0,
  totalPriceFainal: 0,
  isFavorite: false,
  isEnterOfferCode: false,
  offerMessage: "",
  user,
  token,
  filters_bed: 0,
  filters_max_pers: 0,
  filters_low_price: 0,
  filters_high_price: 0,
  filters_start_date: "",
  filters_end_date: "",
  destination: ["Mahajanga"],
  load_destination: false,
  visitor: 0,
  amount_to_pay: 0,
  category: []
};

const filterItemsDate = (list_items, list_date) => {
  let list_final = list_items
  debugger
  for (let i = 0; i < list_final.length; i++) {
    try {
      if (list_final[i].booked.length > 1)
        for (let j = 0; j < list_date.length; j++) {
          if (list_final[i].booked.search(list_date[j]) !== -1) {
            list_items.splice(i, 1, null);
            break;
          }
        }
    } catch {

    }

  }
  list_final = list_items.filter(function (el) {
    return el != null;
  });
  return list_final;
}


var getDateList = function (start, end) {
  for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt).toISOString().slice(0, 10));
  }
  return arr;
};
const filterItemsHandler = (key, value, allProducts, filters, dataFiltered) => {
  let filteredItems = [];

  if (key === 'bed') {
    filteredItems = allProducts.filter((product) => {
      return product.nbr_beds >= parseInt(value) && parseInt(product.price) >= parseInt(filters.low_price) && parseInt(product.price) <= parseInt(filters.high_price);
    });

  }
  else {
    if (key === 'price') {
      filteredItems = allProducts.filter((product) => {
        return parseInt(product.price) >= parseInt(value[1][0]) && parseInt(product.price) <= parseInt(value[1][1]) && parseInt(product.max_pers) >= parseInt(value);
      });
    }
    else if (key === 'max_pers') {
      filteredItems = allProducts.filter((product) => {
        return parseInt(product.price) >= parseInt(filters.low_price) && parseInt(product.price) <= parseInt(filters.high_price) && parseInt(product.max_pers) >= parseInt(value);
      });
    }
    else {
      filteredItems = dataFiltered
    }
  }
  debugger
  let list_date = getDateList(filters.start_date, filters.end_date);
  filteredItems = filterItemsDate(filteredItems, list_date);

  return filteredItems;
};


const sumPrice = (items, isOffer) => {
  debugger
  const totalPrice = items.reduce((totalPrice, product) => {
    return totalPrice + parseFloat(product.price) * product.count;
    //  product.count;
  }, 0);

  if (isOffer) {
    const offerPrice = (totalPrice * offerCode.disCount) / 100;
    const totalPriceAfterOffer = totalPrice - offerPrice;
    localStorage.setItem('totalPrice', JSON.stringify(totalPrice))
    return {
      totalPrice,
      offerPrice,
      totalPriceAfterOffer,
      ...sumPriceWithSend(totalPrice, offerPrice)
    };
  } else {
    localStorage.setItem('totalPrice', JSON.stringify(totalPrice))
    return { totalPrice, ...sumPriceWithSend(totalPrice) };
  }
};

const filterAndMap = (booked) => {
  debugger
  let filterBooked = booked.trano
    .map(function (item) {
      let a = booked.booked.filter(someobject => someobject.trano === item.id)
      item.booked = a.map(b => getDateList(b.dateIn, b.dateOut)).toString();
      return item
    })

  return filterBooked
}

// calc Price With shopping cost
const sumPriceWithSend = (totalPrice, offerPrice = 0) => {
  let totalPriceFainal = null;

  if (totalPrice - offerPrice <= 1_000) {
    totalPriceFainal = totalPrice + sendPrice - offerPrice;
  } else {
    totalPriceFainal = totalPrice - offerPrice;
  }

  return { totalPriceFainal };
};

const reduce = (state, action) => {
  switch (action.type) {
    case "ALL":
      state.filteredItems = [...allProducts];
      return {
        ...state
      };

    case "ADD_FAVORITE": {
      state.allProducts.forEach((product) => {
        if (product.id === action.payload) {
          product.isInterest = !product.isInterest;
          state.favorites = state.allProducts.filter((product) => product.isInterest);
          state.isFavorite = true;
        }
      });
      return {
        ...state
      };
    }
    case "REMOVE_ALL_FAVORITE": {
      state.favorites = [];
      state.allProducts.forEach((product) => {
        product.isInterest = false;
      });

      return {
        ...state
      };
    }
    case "ADD_TO_BASKET": {
      const hasProduct = state.basket.some(
        (product) => product.id === action.payload
      );
      if (state.start_date < state.end_date)
        if (!hasProduct) {
          const mainItem = state.allProducts.find(
            (product) => product.id === action.payload
          );
          mainItem.count = state.day_count;
          mainItem.start_date = state.start_date;
          mainItem.end_date = state.end_date;
          state.basket.push(mainItem);
          localStorage.setItem('basket', JSON.stringify(state.basket))
        }
      return {
        ...state,
        ...sumPrice(state.basket, false)
      };
    }

    case "UPDATE_DATE": {
      if (action.payload.type_ === "start")
        state.start_date = action.payload.date_;
      else
        state.end_date = action.payload.date_;

      state.day_count = action.payload.count;
      return {
        ...state
      };
    }
    case "DESTINATION": {
      state.destination = action.payload.destination;
      return {
        ...state
      };
    }

    case "REMOVE_FROM_BASKET": {
      const indexDelete = state.basket.findIndex(
        (product) => product.id === action.payload
      );
      state.basket[indexDelete].count = 1;
      state.basket = state.basket.filter(
        (product) => product.id !== action.payload
      );
      localStorage.setItem('basket', JSON.stringify(state.basket))
      return {
        ...state,
        ...sumPrice(state.basket, false)
      };
    }
    case "INCREASE": {
      const indexPlus = state.basket.findIndex(
        (product) => product.id === action.payload
      );
      state.basket[indexPlus].count++;

      return {
        ...state,
        ...sumPrice(state.basket, false)
      };
    }
    case "DECREASE": {
      const indexDelete = state.basket.findIndex(
        (product) => product.id === action.payload
      );
      state.basket[indexDelete].count = 1;
      state.basket = state.basket.filter(
        (product) => product.id !== action.payload
      );
      localStorage.setItem('basket', JSON.stringify(state.basket))
      return {
        ...state,
        ...sumPrice(state.basket, false)
      };
    }
    // case "DECREASE": {
    //   const indexMinus = state.basket.findIndex(
    //     (product) => product.id === action.payload
    //   );
    //   if (state.basket[indexMinus].count > 1) {
    //     state.basket[indexMinus].count--;
    //   }

    //   return {
    //     ...state,
    //     ...sumPrice(state.basket, false)
    //   };
    // }
    case "EMPTY_BASKET": {
      state.basket = state.basket.forEach((product) => (product.count = 1));
      state.basket = [];
      localStorage.setItem('basket', JSON.stringify(state.basket))
      return {
        ...state,
        ...sumPrice(state.basket, false)
      };
    }
    case "REMOVE_CLASS": {
      state.isFavorite = false;
      return {
        ...state
      };
    }
    case "OFFER_CODE": {
      if (offerCode.code === action.payload) {
        state.isEnterOfferCode = true;
        state.offerMessage = " kk";
      } else {
        state.offerMessage = "jjj";
      }
      return {
        ...state,
        ...sumPrice(state.basket, state.isEnterOfferCode)
      };
    }
    case "LOGIN":
      // const response = await api.post('/zaho/v1/login/', action.payload);
      let data = action.payload;
      localStorage.setItem('currentUser', JSON.stringify(data))
      state.user = data.profile
      state.token = data.token
      return {
        ...state,
      };

    case "COMPTE":
      // const response = await api.post('/zaho/v1/login/', action.payload);
      let compteData = action.payload;
      state.user = compteData.profile
      return {
        ...state,
      };

    case "UPDATE_PROFILE":
      let data_ = action.payload
      state.user = data_.profile
      return {
        ...state,
      };

    case "LOGOUT":
      state.user = "";
      state.basket = [];
      localStorage.clear()
      return {
        ...state,
      };

    case "OUT_SESION":
      state.user = "";
      state.token = "";
      localStorage.removeItem("currentUser")
      return {
        ...state,
      };


    case "LOAD_BOOKED_TRANO":
      let booked = action.payload;
      let filterd = filterAndMap(booked)
      state.filteredItems = filterd
      return {
        ...state
      };
    case "LOAD_TRANO":
      state.allProducts = action.payload;
      state.filteredItems = [...action.payload];
      let beds = state.allProducts.map(({ nbr_beds }) => nbr_beds);
      let prices = state.allProducts.map(({ price }) => price);
      let max_perss = state.allProducts.map(({ max_pers }) => max_pers);
      state.min_budget = Math.min(...prices);
      state.max_budget = Math.max(...prices);
      state.max_pers = max_perss
      state.beds = beds
      state.filters_max_pers = Math.max(...max_perss)
      state.filters_bed = Math.min(...beds)
      state.filters_low_price = Math.min(...prices);
      state.filters_high_price = Math.max(...prices);
      state.dataLoaded = true;
      return {
        ...state
      };


    case "LOAD_DESTINATION":
      state.destination = action.payload;
      state.load_destination = true;
      return {
        ...state
      };

    case "LOAD_CATEGORY":
      state.category = action.payload;
      return {
        ...state
      };

    case "SET_DESTINATION":
      state.selected_destination = action.payload;
      return {
        ...state
      };

    case "FILTER":
      let keys = action.payload;
      let key = keys.key;
      let value = keys.value;
      let filters = {
        high_price: state.filters_high_price,
        low_price: state.filters_low_price,
        bed: state.filters_bed,
        start_date: state.start_date,
        end_date: state.end_date,
        max_pers: state.filters_max_pers,
      };

      if (key === "bed") {
        state.filters_bed = parseInt(value);
      } else if (key === "price") {
        state.filters_low_price = parseInt(value[1][0]);
        state.filters_high_price = parseInt(value[1][1]);
      } else if (key === "max_pers") {
        state.filters_max_pers = parseInt(value);
      } else if (key === "date") {
        if (value.type_ === "start") {
          state.start_date = value.date_;
        } else {
          state.end_date = value.date_;
        }
        state.day_count = value.count - 1;
        filters.start_date = state.start_date;
        filters.end_date = state.end_date;
      }

      state.filteredItems = filterItemsHandler(
        key,
        value,
        state.allProducts,
        filters,
        state.filteredItems
      );

      return {
        ...state,
      };


    case "LOAD_VISITOR":
      state.visitor = action.payload;
      return {
        ...state
      };

    case "SET_AMOUNT_TO_PAY":
      state.amount_to_pay = action.payload;
      debugger
      return {
        ...state
      };
    case "MAX_PERS":
      return {
        ...state,
        max_pers: action.payload,
      };

    default:
      return state;
  }
};

export const ProductContext = createContext();
export const ProductDispath = createContext();

export default function ContextProvider({ children }) {
  const [state, dispath] = useReducer(reduce, initialState);
  return (
    <ProductContext.Provider value={{ state }}>
      <ProductDispath.Provider value={{ dispath }}>
        {children}
      </ProductDispath.Provider>
    </ProductContext.Provider>
  );
}
