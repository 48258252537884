import React, { } from "react";
import tw from "twin.macro";

const Container = tw.div`relative  ml-[-2rem] mr-[-2rem] mb-[-6rem] bg-cover bg-center bg-no-repeat bg-[url('https://gateway.pinata.cloud/ipfs/QmTwrpUJoKU8HPctaqnpASqRH6uBh4w8v9qQzNqkF3433r')]`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto px-4 py-8`;

// const Container = tw.div`relative bg-cover ml-[-2rem] mr-[-2rem] mb-[-6rem] bg-center bg-no-repeat bg-[url('https://images.pexels.com/photos/346768/pexels-photo-346768.jpeg')]`;

export default () => {

  return (
    <>

      <Container>
        <TwoColumn>.</TwoColumn>
        <TwoColumn>.</TwoColumn>
      </Container>
    </>
  );
};
