import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import styled from 'styled-components';
import tw from 'twin.macro';
// import QueryString from 'query-string';
import { CREATE_CHECKOUT } from 'helpers/api';

const Body = styled.div`
  ${tw`p-4`}
`;

const Backdrop = styled.div`
  ${tw`fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-50 z-10`}
`;
const Container = styled.div`
  ${tw`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg z-20`}
  width: 90%;
  max-width: 600px;
`;


const Modal = () => {
  const [url, setUrl] = useState("")
  const location = useLocation();


  useEffect(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const values = params.get('success');
    console.log(values)

    if (values === "true") {
      window.location.replace("/stripe/deposit/thank-you");
    }

    if (values === "false") {
      console.log(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }

    if (url !== "") {
      window.location.replace(url);
    }

  }, [url]);

  const clicked = async () => {
    let url_ = await CREATE_CHECKOUT()
    console.log(url_)
    setUrl(url_['url'])
  }

  return (
    <Backdrop >
      <Container>
        <Body>
          <section>
            <div className='product'>
              <img
                src='https://i.imgur.com/EHyR2nP.png'
                alt='The cover of Stubborn Attachments'
              />
              <div className='description'>
                <h3>Stubborn Attachments</h3>
                <h5>$20.00</h5>
              </div>
            </div>

            <button className='button' onClick={clicked}>
              Checkout
            </button>

          </section>
        </Body>
      </Container>
    </Backdrop>
  );

};

export default Modal;
