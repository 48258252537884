import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import { ProductDispath } from "Context/ContextProvider";
import styled from "styled-components";
import 'react-toastify/dist/ReactToastify.css';
import { motion } from "framer-motion";
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import { GET_MY_TRANO } from "helpers/api";
import TranoModal from "./TranoModal";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { DETAIL_NO_TRANO } from "helpers/constant";

const Row = tw.div`flex flex-col lg:flex-row -mb-10 w-full lg:mr-12`;
const Heading = tw(SectionHeading)`text-left text-xs`;

const PopularPostsContainer = tw.div`lg:w-2/3`;
const Description = tw.p`mt-2 font-medium text-secondary-100 leading-loose text-sm`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
// const CardContainer = tw.div`mt-10 mb-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const CardContainer = tw.div`mt-10 mb-10 w-full`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;

const SubmitButton = tw.button`w-full sm:w-32 mb-8 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

export default () => {
    const { dispath } = useContext(ProductDispath);
    const [data, setData] = useState()
    const [loaded, setLoaded] = useState()
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const loadData = async () => {

        try {
            let res = await GET_MY_TRANO()
            if (res.status === 200) {
                setData(res.data)
                debugger
                // console.log(res.data)
                setLoaded(true)
            }
        } catch (error) {
            if (error.response.status === 401) {
                alert("Session expiree")
                dispath({ type: "OUT_SESION" });
                navigate("/login")
            }
        }
    };


    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    useEffect(() => {
        !loaded &&
            loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Row>
            <PopularPostsContainer>
                <Heading>Ajout maison dans le site</Heading>
                <Description>
                    Condition d'ajout des maisons:
                </Description>
                <div>
                    <SubmitButton onClick={handleOpenModal}>Ajoutez ici</SubmitButton>
                    {showModal && <TranoModal onClose={handleCloseModal} />}
                </div>

            </PopularPostsContainer>
            <PopularPostsContainer>
                <Heading>Listes de votre maison</Heading>
                {
                    loaded &&
                    data?.map((item, index) =>
                        <CardContainer key={index}>
                            <Card className="group" initial="rest" whileHover="hover" animate="rest">
                                <CardImageContainer imageSrc={item['images']}>
                                </CardImageContainer>
                                <CardText>
                                    {item['is_active'] ?
                                        <Link to={`/trano/details/${item['id']}`}>
                                            <CardTitle >{item['name']}</CardTitle>
                                        </Link> :
                                        <CardTitle >{item['name']}</CardTitle>
                                    }
                                </CardText>
                            </Card>
                        </CardContainer>
                    )
                }
                {
                    data?.length === 0 &&
                    <div className="favorite_empty">
                        <span className="favorite_empty_title">{DETAIL_NO_TRANO}</span>
                    </div>
                }
            </PopularPostsContainer>

        </Row>
    );
};
