import { REGISTER_TRANO } from "helpers/api";
import React from "react";
import { useState } from "react";
import './style/modal.css'
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";

const TranoModal = ({ onClose }) => {
  const PINATA_API_KEY = "c59e76f4ddeae606e42b"
  const PINATA_SECRET_API_KEY = "1dd3e04314058fdecc1051d9e6a5255f2a1e115fda795911c151f97b0d50bacc";

  const buttonStyle = {
    backgroundColor: "blue",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer"
  };

  const [tranoData, setTranoData] = useState({
    destination: "",
    name: "",
    description: "",
    category: "VILLA",
    images: "",
    image_outdoor: "",
    image_indoor: "",
    price: 5000.0,
    is_secured: false,
    tv: false,
    wifi: false,
    can_cook: false,
    max_pers: 2,
    nbr_beds: 1,
    air_con: false,
    parking: false,
    eau_chaude: false,
    spa: false,
    free_breakfast: false,
    restaurant: false,
    piscine: false,
    commission_rate: 10,
    extra: "",
    longitude: -15.716667,
    latitude: 46.316667,
    addresse: "",
    contact: "",
    is_active: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Code to submit the form data to the backend
    debugger
    try {
      let res = await REGISTER_TRANO(tranoData)
      // console.log(res)
      if (res.status === 201) {
        toast.success("Enregistrement success. L'equipe va faire verification des informations avant de publier sur le site. Merci de patienter")
      }
      else {
        toast.error(res.data.detail ?? "An error occurred");
      }
    } catch (error) {
      // console.log(error);
      toast.error(error.response?.data?.detail ?? "An error occurred");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTranoData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const [imagesFile, setImagesFile] = useState({
  //   images: null,
  //   image_indoor: null,
  //   image_outdoor: null,
  // });

  const handleFileUpload = async (event) => {
    const { name } = event.target;
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      // Step 1: Upload file to Pinata
      const response = await axios.post(
        "https://api.pinata.cloud/pinning/pinFileToIPFS",
        formData,
        {
          maxContentLength: "Infinity",
          headers: {
            "Content-Type": "multipart/form-data",
            pinata_api_key: PINATA_API_KEY,
            pinata_secret_api_key: PINATA_SECRET_API_KEY,
          },
        }
      );

      // Step 2: Get URL of the uploaded file
      const url = `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`;
      debugger
      // console.log(url)
      setTranoData((prevState) => ({
        ...prevState,
        [name]: url,
      }));
    } catch (error) {
      console.error(error);
    }

  };

  return (
    <div className="modal-overlay">
      <ToastContainer />
      <div className="modal">
        <div className="modal-header">
          <h2>Formulaire Ajout Trano</h2>
          <button onClick={onClose}>X</button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit} >
            <div className="form-field">
              <label htmlFor="destination">Destination:</label>
              <input
                type="text"
                name="destination"
                id="destination"
                value={tranoData.destination}
                onChange={handleInputChange}
                placeholder="Enter destination"
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                name="name"
                id="name"
                value={tranoData.name}
                onChange={handleInputChange}
                placeholder="Enter name"
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="description">Description:</label>
              <textarea
                name="description"
                id="description"
                value={tranoData.description}
                onChange={handleInputChange}
                placeholder="Enter description"
                required
              ></textarea>
            </div>
            <div className="form-field">
              <label htmlFor="category">Categorie:</label>
              <select
                name="category"
                id="category"
                value={tranoData.category}
                onChange={handleInputChange}
              >
                <option value="VILLA">Villa</option>
                <option value="APPARTEMENT">Appartement</option>
                <option value="STUDIO">Studio</option>
                <option value="HOTEL">Hotel</option>
                <option value="BUNGALOW">Bungallow</option>
              </select>
            </div>
            <div className="form-field">
              <label htmlFor="images">Image presentation:</label>
              <input
                type="file"
                name="images"
                id="images"
                // value={tranoData.images}
                onChange={handleFileUpload}
                placeholder="Enter image URL"
              />
            </div>
            <div className="form-field">
              <label htmlFor="images">Image Exterieur:</label>
              <input
                type="file"
                name="image_outdoor"
                id="image_outdoor"
                // value={tranoData.image_outdoor}
                onChange={handleFileUpload}
                placeholder="Enter image URLs"
              />
            </div>
            <div className="form-field">
              <label htmlFor="images">Image Interieur:</label>
              <input
                type="file"
                name="image_indoor"
                id="image_indoor"
                // value={tranoData.image_indoor}
                onChange={handleFileUpload}
                placeholder="Enter image URLs"
              />
            </div>
            <div className="form-field">
              <label htmlFor="price">Prix Nuite:</label>
              <input
                type="number"
                name="price"
                id="price"
                value={tranoData.price}
                onChange={handleInputChange}
                placeholder="Enter price"
                min="0"
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="max_pers">Max personnes:</label>
              <input
                type="number"
                name="max_pers"
                id="max_pers"
                value={tranoData.max_pers}
                onChange={handleInputChange}
                placeholder="Enter max number of people"
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="nbr_beds">Nombre de lit:</label>
              <input
                type="number"
                name="nbr_beds"
                id="nbr_beds"
                value={tranoData.nbr_beds}
                onChange={handleInputChange}
                placeholder="Enter number of beds"
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="is_secured">Trano securise:</label>
              <input
                type="checkbox"
                name="is_secured"
                id="is_secured"
                checked={tranoData.is_secured}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-field">
              <label htmlFor="tv">Television:</label>
              <input
                type="checkbox"
                name="tv"
                id="tv"
                checked={tranoData.tv}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label htmlFor="wifi">Wifi:</label>
              <input
                type="checkbox"
                name="wifi"
                id="wifi"
                checked={tranoData.wifi}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label htmlFor="can_cook">Cuisine:</label>
              <input
                type="checkbox"
                name="can_cook"
                id="can_cook"
                checked={tranoData.can_cook}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-field">
              <label htmlFor="air_con">Climatiseur:</label>
              <input
                type="checkbox"
                name="air_con"
                id="air_con"
                checked={tranoData.air_con}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label htmlFor="parking">Parking:</label>
              <input
                type="checkbox"
                name="parking"
                id="parking"
                checked={tranoData.parking}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label htmlFor="eau_chaude">Eau chaude:</label>
              <input
                type="checkbox"
                name="eau_chaude"
                id="eau_chaude"
                checked={tranoData.eau_chaude}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label htmlFor="spa">Spa:</label>
              <input
                type="checkbox"
                name="spa"
                id="spa"
                checked={tranoData.spa}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label htmlFor="free_breakfast">Petit-Dejeuner gratuit:</label>
              <input
                type="checkbox"
                name="free_breakfast"
                id="free_breakfast"
                checked={tranoData.free_breakfast}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label htmlFor="restaurant">Restaurant:</label>
              <input
                type="checkbox"
                name="restaurant"
                id="restaurant"
                checked={tranoData.restaurant}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label htmlFor="piscine">Piscine:</label>
              <input
                type="checkbox"
                name="piscine"
                id="piscine"
                checked={tranoData.piscine}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label htmlFor="extra">Extra:</label>
              <textarea
                name="extra"
                id="extra"
                value={tranoData.extra}
                onChange={handleInputChange}
                placeholder="Definir ici ce qui vous rend special"
                required
              ></textarea>
            </div>
            <div className="form-field">
              <label htmlFor="longitude">Longitude:</label>
              <input
                type="number"
                name="longitude"
                id="longitude"
                value={tranoData.longitude}
                onChange={handleInputChange}
                placeholder="46.316667"
              />
            </div>
            <div className="form-field">
              <label htmlFor="latitude">Latitude:</label>
              <input
                type="number"
                name="latitude"
                id="latitude"
                value={tranoData.latitude}
                onChange={handleInputChange}
                placeholder="-15.716667"
              />
            </div>
            <div className="form-field">
              <label htmlFor="address">Address:</label>
              <input
                type="text"
                name="addresse"
                id="addresse"
                value={tranoData.addresse}
                onChange={handleInputChange}
                placeholder="Mahajanga - Amborovy"
              />
            </div>
            <div className="form-field">
              <label htmlFor="contact">Contact:</label>
              <input
                type="text"
                name="contact"
                id="contact"
                value={tranoData.contact}
                onChange={handleInputChange}
                placeholder="0342177510 - 0326731581"
              />
            </div>


            <div>

            </div>
            <button style={buttonStyle} type="submit">Submit</button>

          </form>
        </div>
      </div>
    </div>
  );
};

export default TranoModal;
