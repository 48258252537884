import React, { useContext, useEffect } from "react";
import tw from "twin.macro";
import { Navigate } from 'react-router-dom';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import TabGrid from "./components/TabCardGrid.js";
import Hero from "./components/Hero.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/headers/light.js";
import { ProductContext } from "Context/ContextProvider";
import upslash from "images/upslash.jpeg";

export default () => {
    const { state } = useContext(ProductContext);
    const imageCss = tw`rounded-4xl`;

    return (
        <>
            {
                state.user ?
                    <AnimationRevealPage>
                        <Header />
                        <Hero
                           
                            imageSrc={upslash}
                            imageCss={imageCss}
                            imageDecoratorBlob={true}
                            primaryButtonText="Mahajanga"
                            watchVideoButtonText="Chercher"
                        />
                        <TabGrid
                            heading={
                                <>
                                    {state.user.nom ?
                                        <>
                                            Bienvenue {state.user.nom},
                                        </>
                                        :
                                        <>
                                            Votre compte
                                        </>

                                    }
                                </>
                            }
                            is_admin={state.user.is_admin}
                        />
                        <Footer />
                    </AnimationRevealPage>
                    : <Navigate to="/login" />
            }
        </>

    );
}
