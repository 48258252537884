import { useContext } from 'react';
import styled from 'styled-components';
import ReactSlider from 'react-slider'
import { ProductContext, ProductDispath } from '../../Context/ContextProvider';


const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 10px;
`;

const StyledThumb = styled.div` 
    line-height: 20px;
    width: 10px;
    text-align: center;
    background-color: #d9dde1;
    color: #000;
    border-radius: 50%;
    cursor: grab;
`;
const Thumb = (props, state) => <StyledThumb {...props}>{state.valueNow}</StyledThumb>;

// const StyledTrack = styled.div`
//     top: 0;
//     bottom: 0;
//     height: 20px;
//     background: ${props => (props.index === 2 ? '#6415ff' : props.index === 1 ? '#6415ff' : '#6415ff')};
//     border-radius: 999px;
// `;

const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    height: 20px;
    background: #fff;
    border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const StyledContainer = styled.div`
    overflow-x: clip;
`;

const TooltipSlider = () => {
    const { dispath } = useContext(ProductDispath);
    const { state } = useContext(ProductContext);

    debugger

    const searchPriceRange = (value, index) => {
        dispath({ type: "FILTER", payload: { key: 'price', value: [index, value] } });
    }

    return <>
        <StyledSlider
            min={state.min_budget}
            step={1000}
            max={state.max_budget}
            defaultValue={[state.filters_low_price, state.filters_high_price]}
            renderTrack={Track}
            renderThumb={Thumb}
            onAfterChange={(value, index) => searchPriceRange(value, index)}
        />
    </>

}

export default TooltipSlider