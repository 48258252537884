import React, { useContext, useEffect, useState } from "react";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import macHeroScreenshotImageSrc from "images/capture.png";

import { ProductContext, ProductDispath } from "Context/ContextProvider";
import { ADMIN_WITHDRAW_API, ADMIN_DEPOSIT_API } from "helpers/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FadeLoader from "react-spinners/FadeLoader";

const Container = tw(ContainerBase)`min-h-screen bg-white text-white font-medium flex justify-center -mr-8 -ml-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-2 sm:my-2 bg-white text-gray-900  sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-2 sm:p-2`;
const MainContent = tw.div`mt-6 flex flex-col items-center`;
const Heading = tw.h1`text-2xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const Form = tw.form`mx-auto`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export default ({
    submitButtonText = "Se connecter",

}) => {

    const { state } = useContext(ProductContext);
    const { dispath } = useContext(ProductDispath);
    const [isLogged, setIsLogged] = useState(false)
    const [isLoading, setIsLoading] = useState(false);


    const handleSubmitDeposit = async (event) => {
        //Prevent page reload
        event.preventDefault();
        setIsLoading(true);

        var { profile_id, raison, amount } = event.target.elements;
        // amount = amount.value
        try {
            let res = await ADMIN_DEPOSIT_API({ profile_id: profile_id.value, amount: amount.value, raison: raison.value })
            console.log(res.data.message)
            toast.success(res.data.message)
            // dispath({ type: "LOGIN", payload: res.data });
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            toast.error(error)
        }
    };


    const handleSubmitWithdraw = async (event) => {
        //Prevent page reload
        event.preventDefault();
        setIsLoading(true);

        var { profile_id_1, raison_1, amount_1 } = event.target.elements;
        // amount = amount.value
        try {
            let res = await ADMIN_WITHDRAW_API({ profile_id: profile_id_1.value, amount: amount_1.value, raison: raison_1.value })
            console.log(res.data.message)
            toast.success(res.data.message)
            setIsLogged(true)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            toast.error(error)
        }
    };

    useEffect(() => {

    }, [])

    return (
        <>
            {
                <Container>
                    <ToastContainer />
                    <Content>
                        <MainContainer>
                            <MainContent>
                                <Heading>Credit / Deposit</Heading>
                                <FormContainer>
                                    <Form onSubmit={handleSubmitDeposit}>
                                        <Input required name="profile_id" type="text" placeholder="MGA_9999" />
                                        <Input required name="raison" type="text" placeholder="BONUS" />
                                        <Input required name="amount" type="number" placeholder="1000" />
                                        <SubmitButton type="submit">
                                            {isLoading && <FadeLoader height={6} color="#36d7b7" cssOverride={{ height: '1.5rem', top: '0.5rem' }} />}
                                            <span className="text">DEPOSIT</span>
                                        </SubmitButton>
                                    </Form>
                                </FormContainer>
                            </MainContent>
                        </MainContainer>
                        <MainContainer>
                            <MainContent>
                                <Heading>Retrait</Heading>
                                <FormContainer>
                                    <Form onSubmit={handleSubmitWithdraw}>
                                        <Input required name="profile_id_1" type="text" placeholder="MGA_9999" />
                                        <Input required name="raison_1" type="text" placeholder="TRANSFERT VERS MOBILE MONEY" />
                                        <Input required name="amount_1" type="number" placeholder="1000" />
                                        <SubmitButton type="submit">
                                            {isLoading && <FadeLoader height={6} color="#36d7b7" cssOverride={{ height: '1.5rem', top: '0.5rem' }} />}
                                            <span className="text">WITHDRAW</span>
                                        </SubmitButton>
                                    </Form>
                                </FormContainer>
                            </MainContent>
                        </MainContainer>
                    </Content>
                </Container>
            }

        </>
    )
};
